import { useParams, useSearchParams } from 'react-router-dom'
import { ProjectDetails } from '../components/ProjectDetails'
import useProject from '../hooks/useProject'

export const ProjectDetailsPage = () => {
  const { projectId } = useParams()
  const project = useProject(projectId)
  const [searchParams] = useSearchParams()
  const allowQuoteSubmit = searchParams.get('allowQuoteSubmit')

  return project !== undefined ? (
    <ProjectDetails project={project} allowQuoteSubmit={allowQuoteSubmit} />
  ) : null
}
