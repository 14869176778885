import styles from '../styles/styles.module.css'
import { useRef, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Alert,
  Button,
  Card,
  Form,
  Stack,
  Image
} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../firebase'
import { Footer } from '../components/Footer'
import { PageRoutes } from '../constants'
import imgBulletPoint from '../images/icons/bulletPoint.png'
import imgOurService from '../images/home_our_service.png'

const Login = () => {
  const emailRef = useRef(null)
  const passwordRef = useRef(null)
  const [errorMsg, setErrorMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = async e => {
    e.preventDefault()
    setErrorMsg('')
    setLoading(true)
    if (!passwordRef.current?.value || !emailRef.current?.value) {
      setErrorMsg('Please fill in the fields')
      return
    }

    signInWithEmailAndPassword(
      auth,
      emailRef.current.value,
      passwordRef.current.value
    )
      .then(userCredential => {
        // Signed in
        const user = userCredential.user
        if (user) navigate(PageRoutes.HOME)
      })
      .catch(error => {
        const errorCode = error.code
        const errorMessage = error.message
        setErrorMsg('Email or Password Incorrect')
      })

    setLoading(false)
  }

  return (
    <>
      <Container
        fluid
        className={styles.sideMenuBarBackgroundColor + ' pt-4 px-0'}
      >
        <Row className="justify-content-center py-5">
          <Col sm={12} md={8} lg={6}>
            <Card>
              <Card.Body>
                <h2 className="text-center mb-4">Log in</h2>
                <Form onSubmit={handleSubmit} className="px-3">
                  <Form.Group className="mb-3" id="email">
                    <Form.Label htmlFor="emailControl">Email</Form.Label>
                    <Form.Control
                      type="email"
                      ref={emailRef}
                      required
                      id="emailControl"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" id="password">
                    <Form.Label htmlFor="passwordControl">Password</Form.Label>
                    <Form.Control
                      type="password"
                      ref={passwordRef}
                      required
                      id="passwordControl"
                    />
                  </Form.Group>
                  {errorMsg && (
                    <Alert
                      variant="danger"
                      onClose={() => setErrorMsg('')}
                      dismissible
                      className="my-3"
                    >
                      {errorMsg}
                    </Alert>
                  )}
                  <div className="text-center mt-3">
                    <Button
                      disabled={loading}
                      type="submit"
                      className={styles.button + ' w-50 my-2'}
                    >
                      Login
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
            {/*<div className="w-100 text-center mt-4">
                    New User? <Link to={"/register"}>Sign up</Link>
                </div>*/}
          </Col>
        </Row>
        <hr className="mx-4 my-4" />
        <Row className="justify-content-md-center px-4 py-5">
          <Row className="justify-content-md-center mb-4">
            <Col md={5} className="py-3">
              <Stack direction="vertical" gap={3}>
                <div className="d-flex align-items-center">
                  <Image
                    src={imgBulletPoint}
                    className="pe-3"
                    style={{ height: '15px' }}
                  />
                  <span className={styles.homeSectionTitle}>OUR SERVICE</span>
                </div>
                <div>
                  <span className={styles.homeSectionHeadline}>
                    What we provide
                  </span>
                </div>
              </Stack>
            </Col>
            <Col md={5} className="py-3">
              <Stack direction="vertical" gap={3}>
                <div className={styles.homeSectionContent}>
                  Our platform connects brands with pre-vetted suppliers,
                  offering a seamless way to post jobs and receive competitive
                  quotes. Key benefits include:
                </div>
              </Stack>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={5} className="mb-4">
              <div>
                <Image rounded src={imgOurService} width={'80%'} />
              </div>
            </Col>
            <Col md={5}>
              <Stack direction="vertical" gap={3}>
                <div className="mb-3">
                  <div className={styles.homeSectionTitle + ' mb-2'}>
                    Quality Assurance
                  </div>
                  <div className={styles.homeSectionContent}>
                    We ensure high standards with pre-shipment audits.
                  </div>
                </div>
                <div className="my-3">
                  <div className={styles.homeSectionTitle + ' mb-2'}>
                    Sustainable Practices
                  </div>
                  <div className={styles.homeSectionContent}>
                    We promote eco-friendly materials and practices.
                  </div>
                </div>
                <div className="my-3">
                  <div className={styles.homeSectionTitle + ' mb-2'}>
                    Transparent Pricing
                  </div>
                  <div className={styles.homeSectionContent}>
                    Receive detailed, itemized quotes for easy comparison.
                  </div>
                </div>
                <div className="my-3">
                  <div className={styles.homeSectionTitle + ' mb-2'}>
                    Efficient Collaboration with Supplers
                  </div>
                  <div className={styles.homeSectionContent}>
                    Seamless component design adjustments and revisions in a
                    streamlined process.
                  </div>
                </div>
                <div className="my-3">
                  <div className={styles.homeSectionTitle + ' mb-2'}>
                    Risk Assessment
                  </div>
                  <div className={styles.homeSectionContent}>
                    We offer risk assessments through expert consultations.
                  </div>
                </div>
                <div className="my-3">
                  <div className={styles.homeSectionTitle + ' mb-2'}>
                    Secure Transactions
                  </div>
                  <div className={styles.homeSectionContent}>
                    Use our escrow service and dispute resolution for peace of
                    mind.
                  </div>
                </div>
              </Stack>
            </Col>
          </Row>
        </Row>
        <Footer />
      </Container>
    </>
  )
}

export default Login
