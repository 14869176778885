import styles from '../styles/styles.module.css'
import { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Stack,
  Button,
  Spinner,
  Nav,
  Alert,
  Image
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { auth } from '../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import {
  getBuyerProjectsWithQuotesData,
  getEngagedQuoteStatus,
  getSelectedQuote
} from '../services/buyerServices' //updateProjectStatus
import * as constants from '../constants'
import { PageRoutes } from '../constants'
import iconMessage from '../images/icons/communication.png'
import { getLatestStatusLabel } from '../services/statusServices'
import iconInstruction from '../images/icons/instruction.png'
import { getQuotesWithStatus } from '../utils/quote'
import useUser from '../hooks/useUser'
import { formatDate } from '../utils/timestamp'

export const MyProjects = () => {
  // logged in user
  const [user, userLoading] = useAuthState(auth)
  const userData = useUser(user?.email)

  const [stateChanged, setStateChanged] = useState(false)
  const [myProjectsData, setMyProjectsData] = useState()
  const [engagedQuoteStatusHistory, setEngagedQuoteStatusHistory] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function myProjectsFetchData() {
      try {
        const response = await getBuyerProjectsWithQuotesData(
          auth?.currentUser?.email
        )
        setMyProjectsData(response)
      } catch (error) {
        console.log(error)
      }
      setStateChanged(false)
    }
    async function allProjectsFetchData() {
      try {
        const response = await getBuyerProjectsWithQuotesData('admin')
        setMyProjectsData(response)
      } catch (error) {
        console.log(error)
      }
      setStateChanged(false)
    }

    if (userData) {
      if (userData?.role?.admin) {
        allProjectsFetchData()
      } else {
        myProjectsFetchData()
      }
    }
  }, [stateChanged, userData])

  useEffect(() => {
    if (!myProjectsData || !userData) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [myProjectsData, userData])

  useEffect(() => {
    async function getEngagedQuoteStatusHistoryData() {
      var tempSelectedQuoteStatusHistory = []
      if (myProjectsData) {
        for (const prj of myProjectsData) {
          try {
            const engagedQuote = await getSelectedQuote(prj?.id)
            if (engagedQuote && engagedQuote.length > 0) {
              const response = await getEngagedQuoteStatus(engagedQuote[0]?.id)
              tempSelectedQuoteStatusHistory.push({
                projectId: prj?.id,
                quoteId: engagedQuote[0]?.id,
                statusHistory: response
              })
            }
          } catch (error) {
            console.log(error)
          }
        }
      }
      setEngagedQuoteStatusHistory(tempSelectedQuoteStatusHistory)
    }
    getEngagedQuoteStatusHistoryData()
  }, [myProjectsData])

  return (
    <>
      <Container fluid={true}>
        <Row className="px-2 pt-3 pb-2">
          <Col>
            <h4 className="mb-3">My Projects</h4>
          </Col>
          <Col className="text-end">
            <Button className={styles.button + ' px-4 py-2'}>
              <Nav.Link
                as={Link}
                to={PageRoutes.PROJECT_PROGRESS_BUYER}
                state={JSON.stringify({
                  newProject: true,
                  projectId: null,
                  quote: null
                })}
              >
                <span>+</span>
                <span className="ms-3">New project</span>
              </Nav.Link>
            </Button>
          </Col>
        </Row>
        <Row className="px-2 pb-3">
          {/*<Col xs={12} md={3} xxl={2} className='pb-3'>
                    <Card>
                        <Card.Body>
                            <Row className='py-2'>
                                <Form.Group id="sortByFilter">
                                    <Form.Label htmlFor="sortByControl">
                                        Sort By:
                                    </Form.Label>
                                    <Form.Select id="sortByControl">
                                        <option value="newest">Newest Quote</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>*/}
          {loading ? (
            <Col xs={12} md={9} xxl={10}>
              <Row className="mx-3 my-3">
                Loading ...
                <Spinner
                  animation="border"
                  variant="secondary"
                  role="status"
                  className="mx-3"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </Row>
            </Col>
          ) : (
            <Col className="pb-3">
              {myProjectsData && myProjectsData.length === 0 && (
                <Card className="px-3 py-3">
                  <Col>
                    <Alert
                      className={styles.instructionBackgroundColor + ' my-2'}
                    >
                      <Row className="py-2">
                        <Col xs={2} lg={2} className="text-end">
                          <div className="mb-2">
                            <Image src={iconInstruction} />
                          </div>
                        </Col>
                        <Col xs={10} lg={9}>
                          <p style={{ fontWeight: 'bold' }}>What's next?</p>
                          <p>You have not created any project yet.</p>
                          <p>
                            <div className="mb-2">
                              <span style={{ fontWeight: 'bold' }}>
                                Step 1.
                              </span>{' '}
                              Create a new project.
                            </div>
                            <div className="mb-2">
                              <span style={{ fontWeight: 'bold' }}>
                                Step 2.
                              </span>{' '}
                              Package Maven expert will review the submitted
                              project and provide suggestions.
                            </div>
                            <div className="mb-2">
                              <span style={{ fontWeight: 'bold' }}>
                                Step 3.
                              </span>{' '}
                              Let suppliers submit quotes for your project.
                            </div>
                          </p>
                          <p className="mb-0">
                            If you have any questions in the meantime, please
                            reach out to{' '}
                            <span style={{ fontWeight: 'bold' }}>
                              hello@packagemaven.com
                            </span>
                            !
                          </p>
                        </Col>
                      </Row>
                    </Alert>
                  </Col>
                </Card>
              )}
              {myProjectsData &&
                myProjectsData.map(data => {
                  const quoteId = getQuotesWithStatus(
                    data.quotes,
                    constants.STATUS_QUOTE_SELECTED
                  )[0]?.id

                  const adjustedQuoteId = getQuotesWithStatus(
                    data.quotes,
                    constants.STATUS_QUOTE_ADJUSTED
                  )[0]?.id

                  let projectProgressBuyerUrl = `${PageRoutes.PROJECT_PROGRESS_BUYER}/${data.id}`

                  if (quoteId) {
                    projectProgressBuyerUrl += `?quoteId=${quoteId}`
                    if (adjustedQuoteId) {
                      projectProgressBuyerUrl += `&adjustedQuoteId=${adjustedQuoteId}`
                    }
                  }

                  return (
                    <Card className="mb-3" key={myProjectsData.indexOf(data)}>
                      <Row>
                        <Col>
                          <ListGroup className="list-group-flush">
                            <ListGroup.Item>
                              <Row className="py-2">
                                <Col xs={6} md={4} xxl={1}>
                                  <Stack
                                    direction="vertical"
                                    gap={2}
                                    className="py-2"
                                  >
                                    <div className={styles.cardListItemLabel}>
                                      Brand
                                    </div>
                                    <div className={styles.cardListItemValue}>
                                      {data.brand}
                                    </div>
                                  </Stack>
                                </Col>
                                <Col xs={6} md={4} xxl={3}>
                                  <Stack
                                    direction="vertical"
                                    gap={2}
                                    className="py-2"
                                  >
                                    <div className={styles.cardListItemLabel}>
                                      Project
                                    </div>
                                    <div className={styles.cardListItemValue}>
                                      <Nav.Link
                                        as={Link}
                                        to={`${PageRoutes.PROJECT_DETAILS}/${data.id}`}
                                        style={{ color: 'rgb(13, 110, 253)' }}
                                      >
                                        {data.name}
                                      </Nav.Link>
                                    </div>
                                  </Stack>
                                </Col>
                                <Col xs={6} md={4} xxl={3}>
                                  <Stack
                                    direction="vertical"
                                    gap={1}
                                    className="py-2"
                                  >
                                    <div className={styles.cardListItemLabel}>
                                      Status
                                    </div>
                                    <div className={styles.cardListItemValue}>
                                      <Button
                                        variant={
                                          (data.status ===
                                            constants.STATUS_PROJECT_DRAFT ||
                                            data.status ===
                                            constants.STATUS_PROJECT_INIT_ASSESSMENT ||
                                            data.status ===
                                            constants.STATUS_PROJECT_CANCELLED
                                          )
                                            ? 'secondary'
                                            : data.status ===
                                              constants.STATUS_PROJECT_ACCEPTING_QUOTES
                                              ? 'primary'
                                              : 'success'
                                        }
                                        size="sm"
                                        className="text-start"
                                        disabled={data.status === constants.STATUS_PROJECT_CANCELLED ? true : false}
                                      >
                                        <Nav.Link
                                          as={Link}
                                          to={projectProgressBuyerUrl}
                                        >
                                          {getLatestStatusLabel(
                                            data,
                                            engagedQuoteStatusHistory
                                          ) +
                                            (data.status ===
                                              constants.STATUS_PROJECT_ACCEPTING_QUOTES
                                              ? ' (' +
                                              getQuotesWithStatus(
                                                data.quotes,
                                                constants.STATUS_QUOTE_SUBMITTED
                                              ).length +
                                              ')'
                                              : '')}
                                        </Nav.Link>
                                      </Button>
                                    </div>
                                  </Stack>
                                </Col>
                                <Col xs={6} md={4} xxl={1}>
                                  <Stack
                                    direction="vertical"
                                    gap={2}
                                    className="py-2"
                                  >
                                    <div className={styles.cardListItemLabel}>
                                      Base type
                                    </div>
                                    <div className={styles.cardListItemValue}>
                                      {data?.base?.type}
                                    </div>
                                  </Stack>
                                </Col>
                                <Col xs={6} md={4} xxl={1}>
                                  <Stack
                                    direction="vertical"
                                    gap={2}
                                    className="py-2"
                                  >
                                    <div className={styles.cardListItemLabel}>
                                      Capacity
                                    </div>
                                    <div className={styles.cardListItemValue}>
                                      {data?.capacity} ml
                                    </div>
                                  </Stack>
                                </Col>
                                <Col xs={6} md={4} xxl={1}>
                                  <Stack
                                    direction="vertical"
                                    gap={2}
                                    className="py-2"
                                  >
                                    <div className={styles.cardListItemLabel}>
                                      Quantity
                                    </div>
                                    <div className={styles.cardListItemValue}>
                                      {data?.initOrderQuantity}
                                    </div>
                                  </Stack>
                                </Col>
                                <Col xs={6} md={4} xxl={1}>
                                  <Stack
                                    direction="vertical"
                                    gap={2}
                                    className="py-2"
                                  >
                                    <div className={styles.cardListItemLabel}>
                                      Deliver by
                                    </div>
                                    <div className={styles.cardListItemValue}>
                                      {data?.deliveryDate
                                        ? formatDate(data.deliveryDate)
                                        : ''}
                                    </div>
                                  </Stack>
                                </Col>
                                <Col xs={6} md={4} xxl={1}>
                                  <Stack
                                    direction="vertical"
                                    gap={2}
                                    className="py-2"
                                  >
                                    <div className={styles.cardListItemLabel}>
                                      Quote due
                                    </div>
                                    <div className={styles.cardListItemValue}>
                                      {data.quoteAcceptanceDueDate
                                        ? formatDate(
                                          data.quoteAcceptanceDueDate
                                        )
                                        : 'N/A'}
                                    </div>
                                  </Stack>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          </ListGroup>
                        </Col>
                      </Row>
                    </Card>
                  )
                })}
            </Col>
          )}
        </Row>
      </Container>
    </>
  )
}
