import styles from '../styles/styles.module.css'
import { useState, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import cellEditFactory from 'react-bootstrap-table2-editor'
import { Type } from 'react-bootstrap-table2-editor'

export const EditableTable = props => {
  const columns = [
    {
      dataField: 'id',
      text: 'Id',
      style: { width: '65px' }
    },
    {
      dataField: 'category',
      text: 'Category',
      style: { width: '250px' },
      editor: {
        type: Type.SELECT,
        options: props.type === "unitCost" ? [
          {
            value: 'Base',
            label: 'Base'
          },
          {
            value: 'Closure',
            label: 'Closure'
          },
          {
            value: 'Applicator',
            label: 'Applicator'
          },
          {
            value: 'Base Finish',
            label: 'Base Finish'
          },
          {
            value: 'Closure Finish',
            label: 'Closure Finish'
          },
          {
            value: 'Base Deco',
            label: 'Base Deco'
          },
          {
            value: 'Closure Deco',
            label: 'Closure Deco'
          },
          {
            value: 'Other',
            label: 'Other'
          }
        ] : [
          {
            value: 'New Tooling',
            label: 'New Tooling'
          },
          {
            value: 'Plate Setup',
            label: 'Plate Setup'
          },
          {
            value: 'Pallet',
            label: 'Pallet'
          },
          {
            value: 'Non-deco Samples',
            label: 'Non-deco Samples'
          },
          {
            value: 'Pre-production Samples',
            label: 'Pre-production Samples'
          },
          {
            value: 'Sample Courier',
            label: 'Sample Courier'
          },
          {
            value: 'Final Delivery',
            label: 'Final Delivery'
          },
          {
            value: 'Other',
            label: 'Other'
          }
        ]
      }
    },
    {
      dataField: 'description',
      text: 'Description',
      style: { width: '50%' }
    },
    {
      dataField: 'price',
      type: 'number',
      text: 'Price ($)',
      style: { width: '300px' }
    }
  ]

  const perUnitData = [
    {
      id: 1,
      category: 'Base',
      description: '',
      price: '0'
    },
    {
      id: 2,
      category: 'Closure',
      description: '',
      price: '0'
    },
    {
      id: 3,
      category: 'Applicator',
      description: '',
      price: '0'
    },
    {
      id: 4,
      category: 'Base Finish',
      description: '',
      price: '0'
    },
    {
      id: 5,
      category: 'Closure Finish',
      description: '',
      price: '0'
    },
    {
      id: 6,
      category: 'Base Deco',
      description: '',
      price: '0'
    },
    {
      id: 7,
      category: 'Closure Deco',
      description: '',
      price: '0'
    },
  ]

  const oneTimeData = [
    {
      id: 1,
      category: 'New Tooling',
      description: '',
      price: '0'
    },
    {
      id: 2,
      category: 'Plate Setup',
      description: '',
      price: '0'
    },
    {
      id: 3,
      category: 'Pallet',
      description: '',
      price: '0'
    },
    {
      id: 4,
      category: 'Non-deco Samples',
      description: '',
      price: '0'
    },
    {
      id: 5,
      category: 'Pre-production Samples',
      description: '',
      price: '0'
    },
    {
      id: 6,
      category: 'Sample Courier',
      description: '',
      price: '0'
    },
    {
      id: 7,
      category: 'Final Delivery',
      description: '',
      price: '0'
    }
  ]

  const [tableData, setTableData] = useState(props?.data && props?.data?.length > 0 ? props?.data : (props.type === "unitCost" ? perUnitData : oneTimeData))
  const [rowId, setRowId] = useState(tableData.length + 1)
  const [selectedRows, setSelectedRows] = useState([])

  const selectRow = {
    mode: 'checkbox',
    //clickToSelect: true,
    hideSelectColumn: false,
    hideSelectAll: true,
    selected: selectedRows,
    clickToEdit: true,
    //bgColor: '#c8e6c9',
    onSelect: (row, isSelect, rowIndex, e) => {
      let tempSelectedRows = selectedRows

      if (isSelect) {
        tempSelectedRows?.push(row.id)
      } else {
        const index = tempSelectedRows?.indexOf(row.id)
        if (index !== -1) {
          tempSelectedRows?.splice(index, 1)
        }
      }

      setSelectedRows(tempSelectedRows)
    }
  }

  const handleAddRow = e => {
    let tempTableData = tableData
    tempTableData.push({ id: rowId, category: '', description: '', price: '' })
    setTableData(tempTableData)
    setRowId(rowId + 1)
  }

  const handleDeleteRow = e => {
    if (selectedRows?.length > 0) {
      let tempTableData = tableData
      for (const rowId of selectedRows) {
        if (rowId > 0) {
          tempTableData?.splice(rowId - 1, 1)
        }
      }

      // reset tableData ids
      for (let i = 0; i < tempTableData?.length; i++) {
        let updatedRowData = { ...tempTableData[i], id: i + 1 }
        tempTableData[i] = updatedRowData
      }

      setTableData([...tempTableData])
      setSelectedRows([])
      setRowId(tempTableData?.length + 1)
    }
  }

  function afterSaveCell(oldValue, newValue) {
    /*console.log('--after save cell--');
        console.log('oldValue', oldValue);
        console.log('newValue', newValue);
        console.log(`and the type is ${typeof newValue}`);
        console.log('tableData', tableData);*/
    props.sendToParentTableData(tableData)
  }

  return (
    <>
      <Row>
        <Col>
          <span className={styles.detailItemLabel}>
            Double click table cell to edit
          </span>
        </Col>
        <Col className="text-end">
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={handleDeleteRow}
          >
            Delete row
          </Button>
          <Button
            variant="outline-secondary"
            className="ms-2"
            size="sm"
            onClick={handleAddRow}
          >
            Add row
          </Button>
        </Col>
      </Row>
      <Row className="pt-2">
        <BootstrapTable
          keyField="id"
          data={tableData}
          columns={columns}
          selected={selectedRows}
          selectRow={selectRow}
          cellEdit={cellEditFactory({
            mode: 'dbclick',
            blurToSave: true,
            afterSaveCell
          })}
        />
      </Row>
    </>
  )
}
