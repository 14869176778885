import styles from '../styles/styles.module.css'
import { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Container,
  Row,
  Col,
  Card,
  Stack,
  Button,
  Spinner,
  Accordion,
  Image,
} from 'react-bootstrap'
import {
  setEngagedQuoteStatus,
  getEngagedQuoteStatus
} from '../services/buyerServices'
import { getFile } from '../services/fileStorageServices'
import Messages from './Messages'
import * as constants from '../constants'
import iconMessage from '../images/icons/communication.png'
import { FileGallery } from '../components/FileGallery'
import { sendNotification } from '../services/notificationServices'
import { useToast } from '../ToastContext'

export default function StepSupplierDesignReview(props) {
  const { addToast } = useToast()
  const projectId = props?.projectId;
  const quoteData = props?.quoteData

  const [stateChanged, setStateChanged] = useState(false)
  const [engagedQuoteStatusHistory, setEngagedQuoteStatusHistory] = useState([])

  const [filesRefData, setFilesRefData] = useState(null)
  const [designFiles, setDesignFiles] = useState([])
  const [designFilesLoading, setDesignFilesLoading] = useState(true)

  const completeBtnRef = useRef()

  useEffect(() => {
    async function engagedQuoteFetchData() {
      try {
        const response = await getEngagedQuoteStatus(quoteData?.id)
        setEngagedQuoteStatusHistory(response)
      } catch (error) {
        console.log(error)
      }
    }
    engagedQuoteFetchData()
  }, [quoteData, stateChanged])

  useEffect(() => {
    if (engagedQuoteStatusHistory && engagedQuoteStatusHistory.length > 0) {
      if (
        engagedQuoteStatusHistory[0]?.status ===
        constants.STATUS_ENGAGED_QUOTE_DESIGN_SUBMITTED_FOR_REVIEW
      ) {
        setFilesRefData(engagedQuoteStatusHistory[0]?.values?.designFileRefData)
      } else {
        setDesignFilesLoading(false)
      }
    }
  }, [engagedQuoteStatusHistory])

  useEffect(() => {
    async function designFilesFetchData() {
      try {
        if (filesRefData) {
          var files = []
          for (const data of filesRefData) {
            const response = await getFile(data.blobName)
            const fileExt = data?.blobName
              ?.substring(data?.blobName?.lastIndexOf('.') + 1)
              ?.toLowerCase()
            const base64String = btoa(
              new Uint8Array(response.data[0].data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            )
            const fileObj = {
              base64String: base64String,
              fileExt: fileExt
            }
            files.push(fileObj)
          }
          setDesignFiles(files)
        }
      } catch (error) {
        console.log(error)
      }
    }
    designFilesFetchData()
  }, [filesRefData])

  useEffect(() => {
    if (filesRefData) {
      if (!designFiles || designFiles?.length === 0) {
        setDesignFilesLoading(true)
      } else {
        setDesignFilesLoading(false)
      }
    } else {
      // case where design files are not uploaded
      setDesignFilesLoading(false)
    }
  }, [designFiles])

  const onDesignReviewCompleteSubmit = async e => {
    e.preventDefault()
    if (completeBtnRef.current) {
      completeBtnRef.current.setAttribute('disabled', 'disabled')

      try {
        setEngagedQuoteStatus(
          quoteData?.id,
          constants.STATUS_ENGAGED_QUOTE_DESIGN_REVIEW_COMPLETE,
          null
        ).then(() => {
          sendNotification(constants.NOTI_TYPE_BUYER_DESIGN_ACCEPTED, projectId, quoteData?.id)
          addToast('Completed design review step successfully.', 'success')
          setStateChanged(!stateChanged)
          props.sendToParentStepCompleted()
        })
      } catch {
        addToast('Completing design review step failed, please try again.', 'danger')
      }

      completeBtnRef.current.removeAttribute('disabled')
    }
  }

  return (
    <Container fluid={true} className="px-2">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <Stack direction="horizontal" gap={5}>
              <h4>Design review</h4>
            </Stack>
          </Accordion.Header>
          <Accordion.Body>
            <Row className="px-2 py-3">
              <Card className="px-0">
                <Card.Title className="px-3 pt-3 pb-2">
                  <span>Let's review and provide feedback on designs</span>
                </Card.Title>
                <Card.Body className="pt-2">
                  {designFilesLoading ? (
                    <Row className="px-2">
                      <Col className="py-3">
                        <div className={styles.detailItemLabel}>
                          Design files
                        </div>
                        <Row className="mx-3 my-3">
                          Loading ...
                          <Spinner
                            animation="border"
                            variant="secondary"
                            role="status"
                            className="mx-3"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <>
                      {filesRefData === null && (
                        <Row className="px-2 pb-3">
                          <Col className="py-3">
                            <div className={styles.detailItemLabel}>
                              Design files have not been submitted yet.
                            </div>
                          </Col>
                        </Row>
                      )}
                      {designFiles && designFiles?.length > 0 && (
                        <Row className="px-2 pb-3">
                          <Col className="py-3">
                            <div className={styles.detailItemLabel}>
                              Design files
                            </div>
                            <FileGallery files={designFiles} />
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </Card.Body>
              </Card>
            </Row>
            <Row className="px-2 py-3">
              <Card className="px-0">
                <Card.Title className="px-3 pt-3 pb-2">
                  <Image src={iconMessage} />
                  <span className="ps-3">Questions & feedback</span>
                </Card.Title>
                <Card.Body className="pt-2">
                  <Messages
                    title={'Please submit any comments or questions.'}
                    buttonLabel={'Submit'}
                    quoteId={quoteData?.id}
                    type={constants.TYPE_MSG_QUOTE_DESIGN_REVIEW}
                    numberOfLines={3}
                  //disabled={engagedQuoteStatusHistory.filter(sh => sh.status === constants.STATUS_ENGAGED_QUOTE_DESIGN_SUBMITTED_FOR_REVIEW).length === 0 ? true : false}
                  />
                </Card.Body>
              </Card>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <br />
      <Row className="px-2 pt-2 pb-3">
        <Col md={{ span: 4, offset: 4 }}>
          <div className="d-grid gap-2">
            <Button
              className={styles.button + ' py-2'}
              size="sm"
              ref={completeBtnRef}
              onClick={onDesignReviewCompleteSubmit}
              disabled={
                engagedQuoteStatusHistory?.length > 0 &&
                  engagedQuoteStatusHistory[0]?.status ===
                  constants?.STATUS_ENGAGED_QUOTE_DESIGN_SUBMITTED_FOR_REVIEW
                  ? false
                  : true
              }
            >
              Proceed to next step
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
