import styles from '../styles/styles.module.css'
import { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Spinner,
  Modal,
  Image,
  Button,
  Stack,
  Badge
} from 'react-bootstrap'
import {
  getProjectDataWithAllQuotes,
  getEngagedQuoteStatus,
  getSelectedQuote,
  updateQuoteStatus,
  carryOverEngagedQuoteStatusHistory,
  setEngagedQuoteStatus,
  updatePrevQuoteHistory
} from '../services/buyerServices'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import * as constants from '../constants'
import ProgressBarProject from '../components/ProgressBarProject'
import { ProjectUpload } from '../components/ProjectUpload'
import { ProjectEdit } from '../components/ProjectEdit'
import { StepBuyerInitialAssessment } from '../components/StepBuyerInitialAssessment'
import { StepBuyerSelectSupplierQuote } from '../components/StepBuyerSelectSupplierQuote'
import StepBuyerSampleNonDeco from '../components/StepBuyerSampleNonDeco'
import StepBuyerDesignReview from '../components/StepBuyerDesignReview'
import StepBuyerSamplePreProduction from '../components/StepBuyerSamplePreProduction'
import StepBuyerPurchaseOrder from '../components/StepBuyerPurchaseOrder'
import iconCheckGreen from '../images/icons/checkGreen.png'
import QuoteCard from '../components/QuoteCard'
import { getQuote } from '../services/supplierServices'
import { ProjectDetails } from '../components/ProjectDetails'
import { useToast } from '../ToastContext'
import { fetchInvoice } from '../services/invoiceService'

export const ProjectProgressBuyer = () => {
  const { addToast } = useToast()
  const navigate = useNavigate()
  const { projectId } = useParams()
  const [searchParams] = useSearchParams()
  const newProject = projectId === undefined
  const [quoteData, setQuoteData] = useState()
  const [quoteAdjustedData, setQuoteAdjustedData] = useState()

  const [loading, setLoading] = useState(true)
  const [stateChanged, setStateChanged] = useState(false)
  const [projectData, setProjectData] = useState(null)
  const [engagedQuoteStatusHistory, setEngagedQuoteStatusHistory] = useState([])

  const [currentStep, setCurrentStep] = useState(-1)

  // Modal
  const [showStepCompletionModal, setShowStepCompletionModal] = useState(false)
  const [showProjectDetailsModal, setShowProjectDetailsModal] = useState(false)
  const [showSelectedQuoteModal, setShowSelectedQuoteModal] = useState(false)
  const [showSpinnerModal, setShowSpinnerModal] = useState(false)

  useEffect(() => {
    const quoteId = searchParams.get('quoteId')
    if (quoteId) {
      getQuote(quoteId).then(response => {
        setQuoteData(response)
      })
    }
  }, [searchParams])

  useEffect(() => {
    const adjustedQuoteId = searchParams.get('adjustedQuoteId')

    if (adjustedQuoteId) {
      getQuote(adjustedQuoteId).then(response => {
        setQuoteAdjustedData(response)
      })
    }
  }, [searchParams])

  useEffect(() => {
    async function engagedProjectFetchData() {
      try {
        setProjectData(null)
        const response = await getProjectDataWithAllQuotes(projectId)
        setProjectData(response)
      } catch (error) {
        console.log(error)
      }
    }
    if (projectId) {
      engagedProjectFetchData()
    }
  }, [projectId, stateChanged])

  useEffect(() => {
    async function engagedQuoteFetchData() {
      try {
        const response = await getEngagedQuoteStatus(quoteData?.id)
        setEngagedQuoteStatusHistory(response)
      } catch (error) {
        console.log(error)
      }
    }
    if (quoteData) {
      engagedQuoteFetchData()
    }
  }, [quoteData, stateChanged])

  useEffect(() => {
    async function configureCurrentStep() {
      if (projectData || engagedQuoteStatusHistory.length > 0) {
        if (projectData?.status === constants.STATUS_PROJECT_DRAFT) {
          setCurrentStep(0) // project creation
        } else if (
          projectData?.status === constants.STATUS_PROJECT_INIT_ASSESSMENT
        ) {
          setCurrentStep(1) // initial assessment
        } else if (
          projectData?.status === constants.STATUS_PROJECT_ACCEPTING_QUOTES
        ) {
          setCurrentStep(2) // supplier selection
        } else if (
          //projectData?.status === constants.STATUS_PROJECT_SUPPLIER_SELECTED ||
          quoteData?.status === constants.STATUS_QUOTE_SELECTED &&
          (engagedQuoteStatusHistory.length === 0 ||
            engagedQuoteStatusHistory[0]?.status ===
            constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_REQUESTED ||
            engagedQuoteStatusHistory[0]?.status ===
            constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_SENT ||
            engagedQuoteStatusHistory[0]?.status ===
            constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_DELIVERED ||
            engagedQuoteStatusHistory[0]?.status ===
            constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_ADJUST ||
            engagedQuoteStatusHistory[0]?.status ===
            constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_ADJUSTED_QUOTE_SELECTED)
        ) {
          setCurrentStep(3) // non-deco sample
        } else if (
          engagedQuoteStatusHistory[0]?.status ===
          constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_COMPLETE ||
          engagedQuoteStatusHistory[0]?.status ===
          constants.STATUS_ENGAGED_QUOTE_DESIGN_SUBMITTED_FOR_REVIEW
        ) {
          setCurrentStep(4) // design review
        } else if (
          engagedQuoteStatusHistory[0]?.status ===
          constants.STATUS_ENGAGED_QUOTE_DESIGN_REVIEW_COMPLETE ||
          engagedQuoteStatusHistory[0]?.status ===
          constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_REQUESTED ||
          engagedQuoteStatusHistory[0]?.status ===
          constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_SENT ||
          engagedQuoteStatusHistory[0]?.status ===
          constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_DELIVERED ||
          engagedQuoteStatusHistory[0]?.status ===
          constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_ADJUST ||
          engagedQuoteStatusHistory[0]?.status ===
          constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_ADJUSTED_QUOTE_SELECTED
        ) {
          setCurrentStep(5) // pre-production sample
        } else if (
          engagedQuoteStatusHistory[0]?.status ===
          constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_COMPLETE
        ) {
          setCurrentStep(6)

          const invoice = await fetchInvoice(quoteData.id)

          if (invoice) {
            navigate('/invoice/' + invoice.id)
          }
        } else {
          setCurrentStep(-1) // invalid state
        }
      } else {
        setCurrentStep(0) // new project
      }
    }
    configureCurrentStep()
  }, [projectData, quoteData, engagedQuoteStatusHistory])

  useEffect(() => {
    if (newProject) {
      setLoading(false)
    } else {
      if (!(projectData && currentStep !== -1)) {
        setLoading(true)
      } else {
        setLoading(false)
      }
    }
  }, [projectData, currentStep, newProject])

  const handleGoToNextStep = e => {
    setShowStepCompletionModal(true)
  }

  // this function is called from child component when buyer selected a quote during supplier selection step
  const handleQuoteSelected = async e => {
    try {
      const response = await getSelectedQuote(projectId)
      const selectedQuote = response[0]
      setQuoteData(selectedQuote)
      handleGoToNextStep()
    } catch (error) {
      console.log(error)
    }
  }

  function getUserFriendlyStepName(stepNumber) {
    if (stepNumber === 0) return 'Project creation'
    if (stepNumber === 1) return 'Initial assessment'
    if (stepNumber === 2) return 'Supplier selection'
    if (stepNumber === 3) return 'Non-deco sample'
    if (stepNumber === 4) return 'Design review'
    if (stepNumber === 5) return 'Pre-production sample'
    if (stepNumber === 6) return 'Purchase order'
  }

  // this function is to handle quote selection during sample adjustment process
  function handleSelectQuoteForAdjustment(selectedQuoteData) {
    setShowSpinnerModal(true)
    try {
      // previously selected quote selected again
      if (selectedQuoteData?.id === quoteData?.id) {
        updateQuoteStatus(
          quoteAdjustedData?.id,
          constants.STATUS_QUOTE_ADJUSTED_NOT_SELECTED
        ).then(() => {
          setQuoteAdjustedData(null)
          addToast('Quote selected successfully.', 'success')
          setShowSelectedQuoteModal(false)
        })
      } else {
        // adjusted quote selected
        carryOverEngagedQuoteStatusHistory(quoteData?.id, quoteAdjustedData?.id)
          .then(() => {
            if (currentStep === 3) {
              setEngagedQuoteStatus(
                quoteAdjustedData?.id,
                constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_ADJUSTED_QUOTE_SELECTED,
                { quoteIdFrom: quoteData?.id, quoteIdTo: quoteAdjustedData?.id }
              )
            } else if (currentStep === 5) {
              setEngagedQuoteStatus(
                quoteAdjustedData?.id,
                constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_ADJUSTED_QUOTE_SELECTED,
                { quoteIdFrom: quoteData?.id, quoteIdTo: quoteAdjustedData?.id }
              )
            } else {
              // invalid state
            }
          })
          .then(async () => {
            // carry over previous quote cost
            let tempPrevQuoteHistory = []
            if (quoteData?.prevQuoteHistory) {
              tempPrevQuoteHistory = quoteData?.prevQuoteHistory
            }
            tempPrevQuoteHistory.push({
              index: tempPrevQuoteHistory.length,
              quoteId: quoteData?.id,
              oneTimeCost: quoteData?.oneTimeCost
                ? currentStep === 3 // non-deco sample step
                  ? [
                    quoteData?.oneTimeCost?.find(
                      otc => otc.category === 'Non-deco Samples'
                    ),
                    quoteData?.oneTimeCost?.find(
                      otc => otc.category === 'Sample Courier'
                    )
                  ]
                  : [
                    quoteData?.oneTimeCost?.find(
                      otc => otc.category === 'Pre-production Samples'
                    ),
                    quoteData?.oneTimeCost?.find(
                      otc => otc.category === 'Sample Courier'
                    )
                  ]
                : currentStep === 3 // non-deco sample step
                  ? [{ nonDecoSampleCost: quoteData?.nonDecoSampleCost }]
                  : [{ decoSampleCost: quoteData?.decoSampleCost }]
            })
            await updatePrevQuoteHistory(
              quoteAdjustedData?.id,
              tempPrevQuoteHistory
            )
          })
          .then(() => {
            updateQuoteStatus(
              quoteAdjustedData?.id,
              constants.STATUS_QUOTE_SELECTED
            ).then(() => {
              updateQuoteStatus(
                quoteData?.id,
                constants.STATUS_QUOTE_ADJUSTED_NOT_SELECTED
              ).then(async () => {
                setQuoteAdjustedData(null)
                const response = await getSelectedQuote(projectId)
                const selectedQuote = response[0]
                setQuoteData(selectedQuote)
                addToast('Quote selected successfully.', 'success')
                setShowSelectedQuoteModal(false)
              })
            })
          })
      }
    } catch {
      addToast('Selecting quote failed, please try again.', 'danger')
    }
    setShowSpinnerModal(false)
  }

  return (
    <>
      <Container fluid className="px-3 py-3 position-absolute">
        <Modal show={showStepCompletionModal} centered>
          <Modal.Header className="py-3">
            <Modal.Title>Congratulations!</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <div className="py-2">
              <Image src={iconCheckGreen} width={50} />
            </div>
            <div className="py-3">
              You have successfully completed the{' '}
              {getUserFriendlyStepName(currentStep)} step.
            </div>
            <Row className="py-2 px-5">
              <Stack direction="vertical" gap={2}>
                <Button
                  className={styles.button + ' py-2'}
                  size="sm"
                  onClick={() => {
                    setStateChanged(!stateChanged)
                    setShowStepCompletionModal(false)
                  }}
                >
                  Proceed
                </Button>
              </Stack>
            </Row>
          </Modal.Body>
        </Modal>
        <Modal
          show={showProjectDetailsModal}
          size="xl"
          fullscreen={false}
          centered
          onHide={() => setShowProjectDetailsModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Project details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProjectDetails project={projectData} allowQuoteSubmit={false} />
          </Modal.Body>
        </Modal>
        <Modal
          show={showSelectedQuoteModal}
          size="xl"
          fullscreen={false}
          centered
          onHide={() => setShowSelectedQuoteModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Supplier quote</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {quoteAdjustedData ? (
              <>
                <Row>
                  <Col className="pb-3">
                    <Row className="pb-2">
                      <Stack direction="vertical" gap={2}>
                        <Button
                          variant="outline-primary"
                          className="mx-3"
                          onClick={() =>
                            handleSelectQuoteForAdjustment(quoteData)
                          }
                        >
                          Select
                          <Badge pill bg="success" className="ms-2">
                            Currently Selected
                          </Badge>
                        </Button>
                      </Stack>
                    </Row>
                    <QuoteCard quoteId={quoteData?.id} />
                  </Col>
                  <Col className="pb-3">
                    <Row className="pb-2">
                      <Stack direction="vertical" gap={2}>
                        <Button
                          variant="outline-primary"
                          className="mx-3"
                          onClick={() =>
                            handleSelectQuoteForAdjustment(quoteAdjustedData)
                          }
                        >
                          Select
                          <Badge pill bg="warning" text="dark" className="ms-2">
                            Adjusted
                          </Badge>
                        </Button>
                      </Stack>
                    </Row>
                    <QuoteCard quoteId={quoteAdjustedData.id} />
                  </Col>
                </Row>
              </>
            ) : (
              <QuoteCard quoteId={quoteData?.id} />
            )}
          </Modal.Body>
        </Modal>
        <Modal show={showSpinnerModal} fullscreen centered style={{ backgroundColor: 'transparent', opacity: '0.5' }}>
          <Modal.Body className="text-center align-content-center">
            <Spinner animation='border' role='status'>
              <span className='visually-hidden'>Loading ...</span>
            </Spinner>
          </Modal.Body>
        </Modal>
        {/*<Offcanvas show={showMsgCanvas} placement='end' onHide={handleMsgCanvasClose} scroll={true} backdrop={false} className="w-50">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Messages</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <Messages 
                    title={"Please enter comments, questions, and feedback."} 
                    buttonLabel={"Submit"}
                    quoteId={quoteData?.id} 
                    type={constants.TYPE_MSG_QUOTE_COMMENTS}
                    numberOfLines={3}
                />
                </Offcanvas.Body>
            </Offcanvas>*/}
        <Row className="px-3" id={'projectProgressMainDiv'}>
          {loading ? (
            <Col xs={12}>
              <Row className="mx-3 my-3">
                Loading ...
                <Spinner
                  animation="border"
                  variant="secondary"
                  role="status"
                  className="mx-3"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </Row>
            </Col>
          ) : (
            <Col xs={12}>
              <Row>
                <ProgressBarProject currentStep={currentStep} />
              </Row>
              {projectData && (
                <Row className="pb-3">
                  <Col className="text-end">
                    <Button
                      className={styles.buttonOutline + ' py-2'}
                      size="sm"
                      onClick={() => setShowProjectDetailsModal(true)}
                    >
                      Project details
                    </Button>
                    {quoteData && (
                      <Button
                        className={
                          (quoteAdjustedData
                            ? styles.buttonWarning
                            : styles.buttonOutline) + ' py-2 ms-2'
                        }
                        size="sm"
                        onClick={() => setShowSelectedQuoteModal(true)}
                      >
                        Supplier quote {quoteAdjustedData ? '(2)' : ''}
                      </Button>
                    )}
                  </Col>
                </Row>
              )}
              <Row>
                {projectId ? (
                  <>
                    {currentStep === 0 && ( // project creation - save draft or submit
                      <ProjectEdit
                        projectData={projectData}
                        sendToParentProjectSubmittedForIA={handleGoToNextStep}
                        currentStep={currentStep}
                      />
                    )}
                    {currentStep === 1 && ( // initial assessment
                      <StepBuyerInitialAssessment
                        projectData={projectData}
                        currentStep={currentStep}
                      />
                    )}
                    {currentStep === 2 && ( // supplier selection
                      <StepBuyerSelectSupplierQuote
                        projectData={projectData}
                        quotes={projectData?.quotes}
                        sendToParentQuoteSelected={handleQuoteSelected}
                      />
                    )}
                    {currentStep === 3 && ( // non-deco sample
                      <StepBuyerSampleNonDeco
                        projectId={projectId}
                        //projectData={projectData}
                        quoteData={quoteData}
                        quoteAdjustedData={quoteAdjustedData}
                        sendToParentStepCompleted={handleGoToNextStep}
                      />
                    )}
                    {currentStep === 4 && ( // design review
                      <StepBuyerDesignReview
                        projectId={projectId}
                        quoteData={quoteData}
                        sendToParentStepCompleted={handleGoToNextStep}
                      />
                    )}
                    {currentStep === 5 && ( // pre-production sample
                      <StepBuyerSamplePreProduction
                        projectId={projectId}
                        quoteData={quoteData}
                        quoteAdjustedData={quoteAdjustedData}
                        sendToParentStepCompleted={handleGoToNextStep}
                      />
                    )}
                    {currentStep === 6 && ( // purchase order
                      <StepBuyerPurchaseOrder
                        quoteData={quoteData}
                        sendToParentStepCompleted={handleGoToNextStep}
                      />
                    )}
                  </>
                ) : (
                  <ProjectUpload
                    sendToParentProjectCreated={handleGoToNextStep}
                  /> // new project creation
                )}
              </Row>
            </Col>
          )}
        </Row>
        <div>
          <Col></Col>
        </div>
      </Container>
    </>
  )
}
