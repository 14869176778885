import { useEffect, useState } from 'react'
import { Col, Container, Row, Stack } from 'react-bootstrap'
import { NewsPost } from '../types'
import NewsPostCard from '../components/NewsPost'
import { convertWordpressPostToNewsPost } from '../utils/news'
import { Footer } from '../components/Footer'

const Newsroom = () => {
  const [posts, setPosts] = useState<NewsPost[]>([])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_NEWS_WORDPRESS_API_ENDPOINT}`)
      .then(response => response.json())
      .then(posts => {
        console.log(posts)
        // @ts-ignore
        return posts.map(post => convertWordpressPostToNewsPost(post))
      })
      .then(posts => setPosts(posts))
      .catch(error => console.error('Error fetching posts:', error))
  }, [])

  return (
    <Container fluid={true} className="px-0 py-0">
      <Row className="justify-content-center pb-5">
        <Col xs={12} lg={10} xl={9}>
          <Stack className="mx-4 mx-xl-5">
            <h1 className="pt-5 fw-semibold">Newsroom</h1>
            <h5
              className="pt-3 pb-4 fw-normal col-12 col-xl-8"
              style={{ lineHeight: 1.4 }}
            >
              Stay connected with Package Maven for the latest news, updates,
              and announcements. Keep up with everything happening in our
              dynamic and growing community.
            </h5>
            <hr className="mb-4" style={{ borderColor: '#999' }} />
            <Row className="g-4">
              {posts.map(post => (
                <Col xl={4} key={post.id}>
                  <NewsPostCard post={post} />
                </Col>
              ))}
            </Row>
          </Stack>
        </Col>
      </Row>
      <Footer />
    </Container>
  )
}

export default Newsroom
