import Signature, { SignatureRef } from '@uiw/react-signature'
import { useRef, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'

const useSignature = ({
  onConfirm
}: {
  onConfirm: (signatureSvg: string) => Promise<void>
}) => {
  const $svg = useRef<SignatureRef>(null)
  const [showSignatureModal, setShowSignatureModal] = useState(false)
  const [isTouched, setIsTouched] = useState(false)

  const handleClear = () => {
    $svg.current?.clear()

    setIsTouched(false)
  }

  const handleConfirm = () => {
    const userConfirmed = window.confirm(
      'Are you sure you want to sign the invoice?'
    )

    if (!userConfirmed) {
      return
    }

    const svgElement = $svg.current?.svg
    if (!svgElement) return

    const svgData = new XMLSerializer().serializeToString(svgElement)
    onConfirm(svgData)

    setShowSignatureModal(false)
  }

  const handlePointer = (points: number[][]) => {
    if (points.length > 0) {
      setIsTouched(true)
    }
  }

  return {
    modal: (
      <Modal
        className="signature-modal"
        show={showSignatureModal}
        onHide={() => setShowSignatureModal(false)}
      >
        <Modal.Header className="py-2" closeButton>
          <Modal.Title>Sign and Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="px-2 py-2">
            <Signature
              className="signature-canvas"
              style={{ backgroundColor: 'transparent' }}
              ref={$svg}
              onPointer={handlePointer}
            />
          </Row>
          <Col className="d-flex justify-content-between">
            <Button className="btn-secondary" onClick={handleClear}>
              Clear
            </Button>
            <Button
              className="btn-primary"
              onClick={handleConfirm}
              disabled={!isTouched}
            >
              Confirm
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
    ),
    setShowSignatureModal
  }
}

export default useSignature
