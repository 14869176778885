import { auth, db } from '../firebase'
import {
  doc,
  setDoc,
  getDocs,
  getDoc,
  updateDoc,
  serverTimestamp,
  collection,
  query,
  orderBy,
  limit,
  addDoc,
  where
} from 'firebase/firestore'
import axios from 'axios'
import moment from 'moment'
import { getUser } from './userServices'
import * as constants from '../constants'

// this function retrieves project data for a given email address
export async function getBuyerProjectsWithQuotesData(email) {
  let results = []
  try {
    const projectsRef = collection(db, 'projects')
    let userData
    let projectsQuery
    if (email === 'admin') {
      projectsQuery = query(projectsRef, orderBy('updatedOn', 'desc'))
    } else {
      userData = await getUser(email)
      projectsQuery = query(
        projectsRef,
        where('company', '==', userData?.company),
        orderBy('updatedOn', 'desc')
      )
    }
    const projectDocsSnapshot = await getDocs(projectsQuery)

    if (projectDocsSnapshot.docs.length > 0) {
      var tempProjectsData = []
      for (const projectDoc of projectDocsSnapshot.docs) {
        const quotesColRef = collection(db, 'quotes')
        const quotesQuery = query(
          quotesColRef,
          where('projectRef', '==', projectDoc.ref),
          where('status', 'not-in', [
            constants.STATUS_QUOTE_INIT_ASSESSMENT,
            constants.STATUS_QUOTE_DRAFT
          ]),
          orderBy('updatedOn', 'desc')
        )
        const quotesDocsSnapshot = await getDocs(quotesQuery)

        var tempQuotesData = []
        quotesDocsSnapshot?.forEach(quoteDoc => {
          tempQuotesData.push({ ...quoteDoc.data(), id: quoteDoc.id })
        })

        tempProjectsData.push({
          ...projectDoc.data(),
          id: projectDoc.id,
          quotes: tempQuotesData
        })
      }

      console.log('Retrieved projects successfully')

      results = tempProjectsData
    }
    console.log('Getting project data successful')
  } catch (e) {
    if (email === 'admin') {
      console.error('Error getting project data for admin: ', e)
    } else {
      console.error('Error getting project data with the email: ', e)
    }
  }
  return results
}

export async function getProjectData(projectId) {
  let result = null
  if (projectId) {
    try {
      const projectRef = doc(db, 'projects', projectId)
      const projectQuery = query(projectRef)
      const projectDocSnapshot = await getDoc(projectQuery)

      if (projectDocSnapshot.exists) {
        result = { ...projectDocSnapshot?.data(), id: projectDocSnapshot?.id }
        console.log('Getting project data successful')
      } else {
        console.log('Project does not exist with the specified id')
      }
    } catch (e) {
      console.error('Error getting project data: ', e)
    }
  }
  return result
}

export async function getProjectByRef(projectRef) {
  let result = null
  if (projectRef) {
    try {
      const projectQuery = query(projectRef)
      const projectDocSnapshot = await getDoc(projectQuery)

      if (projectDocSnapshot.exists) {
        result = { ...projectDocSnapshot?.data(), id: projectDocSnapshot?.id }
        console.log('Getting project data by ref successful')
      } else {
        console.log('Project does not exist with the specified ref')
      }
    } catch (e) {
      console.error('Error getting project data by ref: ', e)
    }
  }
  return result
}

export async function getProjectDataWithAllQuotes(projectId) {
  let result = null
  try {
    const projectRef = doc(db, 'projects', projectId)
    const projectQuery = query(projectRef)
    const projectDocSnapshot = await getDoc(projectQuery)

    if (projectDocSnapshot.exists) {
      // get all quotes received
      const quotesColRef = collection(db, 'quotes')
      const quotesQuery = query(
        quotesColRef,
        where('projectRef', '==', projectDocSnapshot?.ref),
        where('status', 'not-in', [
          constants.STATUS_QUOTE_INIT_ASSESSMENT,
          constants.STATUS_QUOTE_DRAFT
        ]),
        orderBy('updatedOn', 'desc')
      )
      const quotesDocsSnapshot = await getDocs(quotesQuery)

      var tempQuotesData = []
      quotesDocsSnapshot?.forEach(quoteDoc => {
        tempQuotesData.push({ ...quoteDoc.data(), id: quoteDoc.id })
      })

      result = {
        ...projectDocSnapshot?.data(),
        id: projectDocSnapshot?.id,
        quotes: tempQuotesData
      }
      console.log('Getting project data successful')
    } else {
      console.log('Project does not exist with the specified id')
    }
  } catch (e) {
    console.error('Error getting project data: ', e)
  }
  return result
}

export async function updateBuyerProfile(
  firstName,
  lastName,
  phone,
  company,
  title,
  officeAddressObj,
  logoFiles
) {
  //console.log("Updating buyer profile in Firestore starting ...");
  try {
    const email = auth.currentUser.email

    const buyerRef = doc(db, 'users', email) // user id is email

    await setDoc(
      buyerRef,
      {
        firstName,
        lastName,
        email,
        phone: phone ?? '',
        company: company ?? '',
        title,
        officeAddress: officeAddressObj,
        isProfileComplete: true,
        updatedBy: email,
        updatedOn: serverTimestamp()
      },
      {
        merge: true
      }
    )

    console.log('Updating buyer profile in Firestore successful')

    if (logoFiles && logoFiles.length > 0) {
      await addBuyerLogoFile(buyerRef.path, logoFiles)
    }

    await updateDoc(buyerRef, {
      'role.buyer': true
    })
    console.log('Setting buyer role in Firestore successful')
  } catch (e) {
    console.error('Error updating buyer profile to Firestore: ', e)
  }
  //console.log("Updating buyer profile in Firestore ended");
}

// this function sends files to express server to upload to Google Cloud Storage.
// also updates reference data of the uploaded files to the doc path provided.
async function addBuyerLogoFile(buyerDocPath, files) {
  try {
    const buyerRef = doc(db, buyerDocPath)

    var formData = new FormData()
    var logoFiles = files != null ? files : []

    for (let i = 0; i < logoFiles.length; i++) {
      formData.append('files', logoFiles[i].file) // files[i].file due to filepond sending wrapper object containing file
    }
    formData.append('folder', 'buyers/' + buyerRef.id + '/logoFiles')

    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_FILES_UPLOAD_API_ENDPOINT}/`,
        formData,
        config
      )
      .then(async res => {
        console.log('Uploading logo files to GCS successful')
        const fileRefData = res?.data?.data
        await setDoc(
          buyerRef,
          {
            logoFileRefData: fileRefData
          },
          {
            merge: true
          }
        )
          .then(() => {
            console.log(
              'Updating buyer with logoFileRefData in Firestore successful'
            )
          })
          .catch(err => {
            console.log(err)
          })
      })
      .catch(err => {
        console.log(err.response.data)
      })
  } catch (e) {
    console.error('Error uploading buyer logo files to GCS: ', e)
  }
}

// this function adds project data to Firestore and calls helper function to upload files to GCS
export async function addProjectData(project, files, status) {
  const projectsRef = collection(db, 'projects')
  const user = await getUser(auth?.currentUser?.email)

  const docRef = await addDoc(
    projectsRef,
    {
      ...project,
      company: user?.company,
      status,
      createdBy: auth?.currentUser?.email,
      createdOn: serverTimestamp(),
      updatedBy: auth?.currentUser?.email,
      updatedOn: serverTimestamp()
    },
    {
      merge: true
    }
  )
  console.log('Adding project data to Firestore successful')

  if (files && files.length > 0) {
    await addProjectFiles(docRef.path, files)
  }

  return docRef
}

// this function sends files to express server to upload to Google Cloud Storage.
// also updates reference data of the uploaded files to the doc path provided.
async function addProjectFiles(projectDocPath, files) {
  try {
    const projectRef = doc(db, projectDocPath)

    var formData = new FormData()
    var projectFiles = files != null ? files : []

    for (let i = 0; i < projectFiles.length; i++) {
      formData.append('files', projectFiles[i].file) // files[i].file due to filepond sending wrapper object containing file
    }
    formData.append('folder', 'projects/' + projectRef.id + '/designFiles') // folder name is project id

    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_FILES_UPLOAD_API_ENDPOINT}/`,
        formData,
        config
      )
      .then(async res => {
        console.log('Uploading project files to GCS successful')
        const fileRefData = res?.data?.data

        await setDoc(
          projectRef,
          {
            fileRefData: fileRefData
          },
          {
            merge: true
          }
        )
          .then(() => {
            console.log(
              'Updating project with fileRefData in Firestore successful'
            )
          })
          .catch(err => {
            console.log(err)
          })
      })
      .catch(err => {
        console.log(err.response.data)
      })
  } catch (e) {
    console.error('Error uploading project files to GCS: ', e)
  }
}

// this function updates project data to Firestore and calls helper function to upload files to GCS
export async function updateProjectData(projectId, project, files, status) {
  const projectRef = doc(db, 'projects', projectId)
  const user = await getUser(auth?.currentUser?.email)

  await setDoc(
    projectRef,
    {
      ...project,
      company: user?.company,
      status, // draft or submit for init assessment
      updatedBy: auth?.currentUser?.email,
      updatedOn: serverTimestamp()
    },
    {
      merge: true
    }
  )

  console.log('Updating project data to Firestore successful')

  if (files && files.length > 0) {
    await addProjectFiles(projectRef.path, files)
  } else {
    // TODO: unlinkProjectFiles only unlink project files. To save storage, we need to remove the actual files
    await unlinkProjectFiles(projectRef.path)
  }
}

// this function updates project status in Firestore
export async function updateProjectStatus(projectId, status) {
  try {
    const projectRef = doc(db, 'projects', projectId)

    await setDoc(
      projectRef,
      {
        status: status,
        updatedBy: auth?.currentUser?.email,
        updatedOn: serverTimestamp()
      },
      {
        merge: true
      }
    )
      .then(async () => {
        console.log('Updating project status to Firestore successful')
      })
      .catch(err => {
        console.log(err)
      })
  } catch (e) {
    console.error('Error updating project status to Firestore: ', e)
  }
}

// this function updates quote status in Firestore
export async function updateQuoteStatus(quoteId, status) {
  try {
    const quoteRef = doc(db, 'quotes', quoteId)

    if (status === constants.STATUS_QUOTE_SUBMITTED) {
      await setDoc(
        quoteRef,
        {
          status: status,
          updatedBy: auth?.currentUser?.email,
          updatedOn: serverTimestamp(),
          submittedOn: serverTimestamp()
        },
        {
          merge: true
        }
      )
        .then(() => {
          console.log('Updating quote status to Firestore successful')
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      await setDoc(
        quoteRef,
        {
          status: status,
          updatedBy: auth?.currentUser?.email,
          updatedOn: serverTimestamp()
        },
        {
          merge: true
        }
      )
        .then(() => {
          console.log('Updating quote status to Firestore successful')
        })
        .catch(err => {
          console.log(err)
        })
    }
  } catch (e) {
    console.error('Error updating quote status to Firestore: ', e)
  }
}

// this function should result in 1 quote
export async function getSelectedQuote(projectId) {
  let results = []
  try {
    const quotesRef = collection(db, 'quotes')
    const projectRef = doc(db, 'projects', projectId)
    const q = query(
      quotesRef,
      where('projectRef', '==', projectRef),
      where('status', '==', constants.STATUS_QUOTE_SELECTED),
      orderBy('updatedOn', 'desc')
    )
    const quotesQuerySnapshot = await getDocs(q)

    for (const quoteDoc of quotesQuerySnapshot.docs) {
      results.push({ ...quoteDoc.data(), id: quoteDoc.id })
    }
  } catch (e) {
    console.log(
      'Error retrieving quotes from Firestore for the given project by the current user: ',
      e
    )
  }
  return results
}

// carry over previous quote values when a new adjusted quote is selected
export async function updatePrevQuoteHistory(quoteId, value) {
  const quoteRef = doc(db, 'quotes', quoteId)
  await setDoc(
    quoteRef,
    {
      prevQuoteHistory: value,
      updatedBy: auth?.currentUser?.email,
      updatedOn: serverTimestamp()
    },
    {
      merge: true
    }
  )
    .then(() => {
      console.log(
        'Updating previous quote history for newly selected quote to Firestore successful'
      )
    })
    .catch(err => {
      console.log(err)
    })
}

export async function carryOverEngagedQuoteStatusHistory(
  quoteIdFrom,
  quoteIdTo
) {
  try {
    const engagedQuoteRef = collection(db, 'engagedQuote')
    const quoteFromRef = doc(db, 'quotes', quoteIdFrom)

    const q = query(
      engagedQuoteRef,
      where('quoteRef', '==', quoteFromRef),
      orderBy('createdOn', 'asc')
    )
    const engagedQuoteFromQuerySnapshot = await getDocs(q)

    for (const engagedQuoteDoc of engagedQuoteFromQuerySnapshot.docs) {
      const statusToCopy = engagedQuoteDoc.data().status
      const valuesToCopy = engagedQuoteDoc.data().values
      await setEngagedQuoteStatus(quoteIdTo, statusToCopy, valuesToCopy)
    }
  } catch (e) {
    console.log('Error carrying over engagedQuote history', e)
  }
}

// this function adds/sets engaged quote status in Firestore and calls helper function to upload files to GCS
// engaged quote status is different from quote status
export async function setEngagedQuoteStatus(quoteId, status, values) {
  try {
    const quoteRef = doc(db, 'quotes', quoteId)

    if (status === constants.STATUS_ENGAGED_QUOTE_DESIGN_SUBMITTED_FOR_REVIEW) {
      // upload files to GCS
      await addDoc(collection(db, 'engagedQuote'), {
        quoteRef: quoteRef,
        status: status,
        //values: values, // to be added after files uploaded to GCS
        createdBy: auth?.currentUser?.email,
        createdOn: serverTimestamp()
      })
        .then(async docRef => {
          console.log('Setting engaged quote status to Firestore successful')
          await addEngagedQuoteFiles(docRef?.path, values?.designFiles)
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      await addDoc(collection(db, 'engagedQuote'), {
        quoteRef: quoteRef,
        status: status,
        values: values,
        createdBy: auth?.currentUser?.email,
        createdOn: serverTimestamp()
      })
        .then(() => {
          console.log('Setting engaged quote status to Firestore successful')
        })
        .catch(err => {
          console.log(err)
        })
    }
  } catch (e) {
    console.error('Error setting engaged quote status to Firestore: ', e)
  }
}

// this function sends files to express server to upload to Google Cloud Storage.
// also updates reference data of the uploaded files to the doc path provided.
async function addEngagedQuoteFiles(docPath, files) {
  try {
    //console.log("files", files);
    const engagedQuoteRef = doc(db, docPath)

    var formData = new FormData()
    var designFiles = files != null ? files : []

    for (let i = 0; i < designFiles.length; i++) {
      formData.append('files', designFiles[i].file) // files[i].file due to filepond sending wrapper object containing file
    }
    formData.append(
      'folder',
      'engagedQuotes/' + engagedQuoteRef.id + '/designFiles'
    ) // folder name is engagedQuote id

    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_FILES_UPLOAD_API_ENDPOINT}/`,
        formData,
        config
      )
      .then(async res => {
        console.log('Uploading engaged quote design files to GCS successful')
        const fileRefData = res?.data?.data
        await setDoc(
          engagedQuoteRef,
          {
            values: { designFileRefData: fileRefData }
          },
          {
            merge: true
          }
        )
          .then(() => {
            console.log(
              'Updating engaged quote with design file reference in Firestore successful'
            )
          })
          .catch(err => {
            console.log(err)
          })
      })
      .catch(err => {
        console.log(err.response.data)
      })
  } catch (e) {
    console.error('Error uploading engaged quote design files to GCS: ', e)
  }
}

// this function returns all engaged status for the given quote
export async function getEngagedQuoteStatus(quoteId) {
  let results = []
  try {
    const engagedQuotesRef = collection(db, 'engagedQuote')
    const quoteRef = doc(db, 'quotes', quoteId)
    const q = query(
      engagedQuotesRef,
      where('quoteRef', '==', quoteRef),
      orderBy('createdOn', 'desc')
    )
    const engagedQuoteStatusQuerySnapshot = await getDocs(q)

    for (const engagedQuoteStatusDoc of engagedQuoteStatusQuerySnapshot.docs) {
      results.push(engagedQuoteStatusDoc.data())
    }
  } catch (e) {
    console.log(
      'Error retrieving engaged status from Firestore for the given quote: ',
      e
    )
  }
  return results
}

export const unlinkProjectFiles = async projectDocPath => {
  const projectRef = doc(db, projectDocPath)

  try {
    await setDoc(
      projectRef,
      {
        fileRefData: []
      },
      {
        merge: true
      }
    )
    console.log(
      'Unlinking project files with fileRefData in Firestore successful'
    )
  } catch (err) {
    console.log(err)
  }
}
