import { useMemo } from 'react'
import { User } from '../types'
import { formatAddress } from '../utils/address'
import { Button, Col, Row } from 'react-bootstrap'

const UserSection = ({
  header,
  user,
  onEdit
}: {
  header: string
  user: User
  onEdit?: () => void
}) => {
  const address = useMemo(() => {
    if (user.officeAddress !== undefined) {
      return formatAddress(user.officeAddress)
    }

    return user.location
  }, [user])

  return (
    <>
      <Row className="px-0" style={{}}>
        <Col>
          <h5 className="fw-bold">{header}</h5>
        </Col>
        <Col className="text-end mx-0 px-0">
          {onEdit !== undefined && (
            <Button variant="outline-secondary" size="sm" onClick={onEdit}>
              Edit
            </Button>
          )}
        </Col>
        <p>
          {user.company}
          {user.email && (
            <>
              <br />
              {user.email}
            </>
          )}
          {user.phone && (
            <>
              <br />
              {user.phone}
            </>
          )}
          {address && (
            <>
              <br />
              {address}
            </>
          )}
        </p>
      </Row>
    </>
  )
}

export default UserSection
