import { useCallback } from 'react'
import { getInvoice } from '../services/invoiceService'
import { Invoice } from '../types'
import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query'

const useInvoice = (invoiceId: string | undefined) => {
  const queryKey = ['invoice', invoiceId]
  const queryClient = useQueryClient()
  const { data: invoice }: UseQueryResult<Invoice, Error> = useQuery({
    queryKey,
    queryFn: () => {
      if (!invoiceId) {
        throw new Error('invoiceId is required')
      }

      return getInvoice(invoiceId)
    },
    enabled: !!invoiceId
  })

  const invalidateInvoice = useCallback(() => {
    queryClient.invalidateQueries({ queryKey })
  }, [])

  return { invoice, invalidateInvoice }
}

export default useInvoice
