import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  serverTimestamp,
  updateDoc
} from 'firebase/firestore'
import { DemoRequest, DemoRequestForm, DemoRequestStatus } from '../types'
import { db } from '../firebase'

export async function requestDemo(demoRequestForm: DemoRequestForm) {
  try {
    await addDoc(collection(db, 'demoRequests'), {
      ...demoRequestForm,
      createdOn: serverTimestamp()
    })
  } catch (e) {
    throw new Error(`Error requesting demo request: ${e}`)
  }
}

export async function fetchDemoRequests(): Promise<DemoRequest[]> {
  try {
    const querySnapshot = await getDocs(collection(db, 'demoRequests'))
    const demoRequests = querySnapshot.docs.map(doc => ({
      id: doc.id,
      status: DemoRequestStatus.Pending,
      ...doc.data()
    })) as DemoRequest[]
    return demoRequests
  } catch (e) {
    throw new Error(`Error fetching demo requests: ${e}`)
  }
}

export async function updateDemoRequestStatus(
  id: string,
  newStatus: DemoRequestStatus
) {
  const requestRef = doc(db, 'demoRequests', id)
  try {
    await updateDoc(requestRef, {
      status: newStatus
    })
  } catch (error) {
    console.error('Error updating demo request status:', error)
    throw error
  }
}

export async function deleteDemoRequest(id: string) {
  try {
    await deleteDoc(doc(db, 'demoRequests', id)) // Firestore에서 항목 삭제
  } catch (error) {
    console.error('Error deleting demo request:', error)
  }
}
