import styles from '../styles/styles.module.css'
import { useState } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import cellEditFactory from 'react-bootstrap-table2-editor'
import { Type } from 'react-bootstrap-table2-editor'

export const EditInvoiceCostsTable = props => {
  const columns = [
    {
      dataField: 'id',
      text: 'Id',
      style: { width: '65px' }
    },
    {
      dataField: 'category',
      text: 'Category',
      style: { width: '250px' },
      editor: {
        type: Type.SELECT,
        options: [
          {
            value: 'New Tooling',
            label: 'New Tooling'
          },
          {
            value: 'Plate Setup',
            label: 'Plate Setup'
          },
          {
            value: 'Pallet',
            label: 'Pallet'
          },
          {
            value: 'Non-deco Samples',
            label: 'Non-deco Samples'
          },
          {
            value: 'Pre-production Samples',
            label: 'Pre-production Samples'
          },
          {
            value: 'Sample Courier',
            label: 'Sample Courier'
          },
          {
            value: 'Final Delivery',
            label: 'Final Delivery'
          },
          {
            value: 'Other',
            label: 'Other'
          }
        ]
      }
    },
    {
      dataField: 'description',
      text: 'Description',
      style: { width: '50%' }
    },
    {
      dataField: 'price',
      type: 'number',
      text: 'Price ($)',
      style: { width: '300px' }
    }
  ]

  const oneTimeData = [
    {
      category: 'Other',
      description: '',
      price: 0
    }
  ]

  const [tableData, setTableData] = useState(props?.data ?? oneTimeData)
  const [rowId, setRowId] = useState(tableData.length + 1)
  const [selectedRows, setSelectedRows] = useState([])

  const selectRow = {
    mode: 'checkbox',
    //clickToSelect: true,
    hideSelectColumn: false,
    hideSelectAll: true,
    selected: selectedRows,
    clickToEdit: true,
    //bgColor: '#c8e6c9',
    onSelect: (row, isSelect, rowIndex, e) => {
      let tempSelectedRows = selectedRows

      if (isSelect) {
        tempSelectedRows?.push(row.id)
      } else {
        const index = tempSelectedRows?.indexOf(row.id)
        if (index !== -1) {
          tempSelectedRows?.splice(index, 1)
        }
      }

      setSelectedRows(tempSelectedRows)
    }
  }

  const handleAddRow = e => {
    let tempTableData = tableData
    tempTableData.push({ id: rowId, category: '', description: '', price: 0 })
    setTableData(tempTableData)
    setRowId(rowId + 1)
  }

  const handleDeleteRow = e => {
    if (selectedRows?.length > 0) {
      let tempTableData = tableData
      for (const rowId of selectedRows) {
        if (rowId > 0) {
          tempTableData?.splice(rowId - 1, 1)
        }
      }

      // reset tableData ids
      for (let i = 0; i < tempTableData?.length; i++) {
        let updatedRowData = { ...tempTableData[i], id: i + 1 }
        tempTableData[i] = updatedRowData
      }

      setTableData([...tempTableData])
      setSelectedRows([])
      setRowId(tempTableData?.length + 1)
    }
  }

  function afterSaveCell(oldValue, newValue) {
    props.onEdit(tableData)
  }

  return (
    <Row className="px-0 mx-0">
      <Col>
        <h5 className="fw-bold">Costs</h5>
      </Col>
      <Row className="pe-0">
        <Col>
          <span className={styles.detailItemLabel}>
            Double click table cell to edit
          </span>
        </Col>
        <Col className="text-end">
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={handleDeleteRow}
          >
            Delete row
          </Button>
          <Button
            variant="outline-secondary"
            className="ms-2"
            size="sm"
            onClick={handleAddRow}
          >
            Add row
          </Button>
        </Col>
      </Row>
      <Row className="pt-2 pe-0">
        <BootstrapTable
          keyField="id"
          data={tableData}
          columns={columns}
          selected={selectedRows}
          selectRow={selectRow}
          cellEdit={cellEditFactory({
            mode: 'dbclick',
            blurToSave: true,
            afterSaveCell
          })}
        />
      </Row>
    </Row>
  )
}
