import { useEffect, useState } from 'react'
import { getQuote } from '../services/supplierServices'
import { Quote } from '../types'

const useQuote = (quoteId: string | undefined) => {
  const [quote, setQuote] = useState<Quote>()

  useEffect(() => {
    if (quoteId) {
      getQuote(quoteId).then(response => {
        if (response) {
          setQuote(response as Quote)
        }
      })
    }
  }, [quoteId])

  return quote
}

export default useQuote
