import './styles/App.css'
import { Routes, Route } from 'react-router-dom'
import { PageLayout } from './components/PageLayout'
import { Home } from './pages/Home'
import { Projects } from './pages/Projects'
import { MyQuotes } from './pages/MyQuotes'
import { ProjectProgressBuyer } from './pages/ProjectProgressBuyer'
import { ProjectProgressSupplier } from './pages/ProjectProgressSupplier'
import { QuoteDetails } from './pages/QuoteDetails'
import { ProjectDetailsPage } from './pages/ProjectDetailsPage'
import { MyProjects } from './pages/MyProjects'
import { RegisterProfile } from './pages/RegisterProfile'
import { InitialAssessment } from './pages/InitialAssessment'
import { PrivacyPolicy } from './pages/PrivacyPolicy'

import AuthRoute from './components/AuthRoute'
import Register from './pages/Register'
import Login from './pages/Login'
import { PageRoutes } from './constants'
import Newsroom from './pages/Newsroom'
import NewsPostPage from './pages/NewsPostPage'
import RequestDemo from './pages/BookDemo'
import DemoRequests from './pages/DemoRequests'
import InvoicePage from './pages/InvoicePage'

//import PasswordReset from './pages/PasswordReset';

const Pages = () => {
  return (
    <Routes>
      <Route element={<AuthRoute />}>
        <Route path={PageRoutes.HOME} element={<Home />} />
        <Route path={PageRoutes.BROWSE_PROJECTS} element={<Projects />} />
        <Route path={PageRoutes.MY_QUOTES} element={<MyQuotes />} />
        <Route
          path={PageRoutes.PROJECT_PROGRESS_BUYER + '/:projectId?'}
          element={<ProjectProgressBuyer />}
        />
        <Route
          path={PageRoutes.PROJECT_PROGRESS_SUPPLIER + '/:projectId'}
          element={<ProjectProgressSupplier />}
        />
        <Route
          path={PageRoutes.PROJECT_DETAILS + '/:projectId'}
          element={<ProjectDetailsPage />}
        />
        <Route
          path={PageRoutes.QUOTE_DETAILS + '/:quoteId'}
          element={<QuoteDetails />}
        />
        <Route
          path={PageRoutes.INVOICE + '/:invoiceId'}
          element={<InvoicePage />}
        />
        <Route path={PageRoutes.MY_PROJECTS} element={<MyProjects />} />
        <Route
          path={PageRoutes.INIT_ASSESSMENT}
          element={<InitialAssessment />}
        />
        <Route
          path={PageRoutes.REGISTER_PROFILE}
          element={<RegisterProfile />}
        />
      </Route>
      <Route path="/" element={<Home />} />
      <Route path={PageRoutes.REGISTER} element={<Register />} />
      <Route path={PageRoutes.LOGIN} element={<Login />} />
      <Route path={PageRoutes.PRIVACY_POLICY} element={<PrivacyPolicy />} />
      {/*<Route path="/passwordreset" element={<PasswordReset />} />*/}
      <Route path={PageRoutes.NEWS} element={<Newsroom />} />
      <Route path={PageRoutes.NEWS + '/:slug'} element={<NewsPostPage />} />
      <Route path={PageRoutes.DEMO} element={<RequestDemo />} />
      <Route path={PageRoutes.DEMO_REQUESTS} element={<DemoRequests />} />
    </Routes>
  )
}

function App() {
  return (
    <PageLayout>
      <Pages />
    </PageLayout>
  )
}

export default App
