import { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { User } from '../types'
import { formatAddress } from '../utils/address'
import { SubmitHandler, useForm } from 'react-hook-form'

const useEditBillingUser = ({
  title,
  billingUser,
  onSave
}: {
  title: string
  billingUser?: User
  onSave: (user: User) => void
}) => {
  const [showEditPersonModal, setShowEditPersonModal] = useState(false)
  const [isTouched, setIsTouched] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid }
  } = useForm<User>({
    mode: 'onChange',
    defaultValues: {
      company: billingUser?.company,
      email: billingUser?.email,
      phone: billingUser?.phone,
      location:
        billingUser?.officeAddress !== undefined
          ? formatAddress(billingUser.officeAddress)
          : billingUser?.location
    }
  })

  const onSubmit: SubmitHandler<User> = async (formData, event) => {
    event?.stopPropagation()

    try {
      onSave(formData)

      setShowEditPersonModal(false)
    } catch (err) {}
  }

  useEffect(() => {
    reset({
      company: billingUser?.company,
      email: billingUser?.email,
      phone: billingUser?.phone,
      location:
        billingUser?.officeAddress !== undefined
          ? formatAddress(billingUser.officeAddress)
          : billingUser?.location
    }) // 초기값이 변경되면 폼 필드 값 업데이트
  }, [billingUser, reset])

  return {
    modal: (
      <Modal
        className="signature-modal"
        show={showEditPersonModal}
        onHide={() => setShowEditPersonModal(false)}
      >
        <Modal.Header className="py-2" closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="px-0 py-2">
              <Form.Group className="mb-3">
                <Form.Label htmlFor="nameControl">Name *</Form.Label>
                <Form.Control
                  type="text"
                  id="nameControl"
                  placeholder="Enter name"
                  {...register('company', {
                    required: 'Name is required'
                  })}
                />
                {errors.company && (
                  <p className="error">{errors.company.message}</p>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="emailControl">Email *</Form.Label>
                <Form.Control
                  type="text"
                  id="emailControl"
                  placeholder="Enter email"
                  {...register('email', {
                    required: 'Email is required'
                  })}
                />
                {errors.email && (
                  <p className="error">{errors.email.message}</p>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="phoneControl">Phone *</Form.Label>
                <Form.Control
                  type="text"
                  id="phoneControl"
                  placeholder="Enter phone"
                  {...register('phone', {
                    required: 'Phone is required'
                  })}
                />
                {errors.phone && (
                  <p className="error">{errors.phone.message}</p>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="addressControl">Address *</Form.Label>
                <Form.Control
                  type="text"
                  id="addressControl"
                  placeholder="Enter address"
                  {...register('location', {
                    required: 'Location is required'
                  })}
                />
                {errors.location && (
                  <p className="error">{errors.location.message}</p>
                )}
              </Form.Group>
            </Row>
            <Col className="mt-2 text-end">
              <Button className="btn-primary" type="submit" disabled={!isValid}>
                Save
              </Button>
            </Col>
          </Form>
        </Modal.Body>
      </Modal>
    ),
    setShowEditPersonModal
  }
}

export default useEditBillingUser
