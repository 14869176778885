import { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Invoice, OnetimeCost } from '../types'
import { EditInvoiceCostsTable } from './EditInvoiceCostsTable'
import useUser from '../hooks/useUser'
import useQuote from '../hooks/useQuote'
import useProject from '../hooks/useProject'
import UserSection from './UserSection'
import useEditBillingUser from '../hooks/useEditBillingUser'

const EditInvoice = ({
  invoice,
  onSave
}: {
  invoice: Invoice
  onSave: (editedInvoice: Invoice) => Promise<void>
}) => {
  const quote = useQuote(invoice?.quoteRef.id)
  const project = useProject(quote?.projectRef.id)

  const buyer = useUser(project?.createdBy)
  const supplier = useUser(quote?.createdBy)
  const [editedInvoice, setEditedInvoice] = useState(invoice)
  const [isSaving, setIsSaving] = useState(false)

  const billedTo = editedInvoice.billedTo ?? buyer
  const from = editedInvoice.from ?? supplier
  const {
    setShowEditPersonModal: setShowEditBilledToModal,
    modal: modalEditBilledTo
  } = useEditBillingUser({
    title: 'Edit Bill To',
    billingUser: billedTo,
    onSave: user => {
      setEditedInvoice({
        ...editedInvoice,
        billedTo: user
      })
    }
  })
  const { setShowEditPersonModal: setShowEditFromModal, modal: modalEditFrom } =
    useEditBillingUser({
      title: 'Edit From',
      billingUser: from,
      onSave: user => {
        setEditedInvoice({
          ...editedInvoice,
          from: user
        })
      }
    })

  const handleEditCosts = (costs: OnetimeCost[]) => {
    setEditedInvoice({
      ...editedInvoice,
      editedCosts: costs
    })
  }

  const handleSave = async () => {
    setIsSaving(true)

    try {
      await onSave(editedInvoice)
    } finally {
      setIsSaving(false)
    }
  }

  const handleEditBilledTo = () => {
    setShowEditBilledToModal(true)
  }

  const handleEditFrom = () => {
    setShowEditFromModal(true)
  }

  if (!quote || !project || !invoice) {
    return <div>Loading...</div>
  }

  return (
    <>
      <Form onSubmit={handleSave}>
        <Row className="pb-2">
          <EditInvoiceCostsTable
            data={invoice.editedCosts}
            onEdit={handleEditCosts}
          />
        </Row>
        <Row className="py-4 px-3">
          {billedTo && (
            <UserSection
              header="Billed To"
              user={billedTo}
              onEdit={handleEditBilledTo}
            />
          )}
        </Row>

        {/* From Section */}
        <Row className="py-4 px-3 border-top">
          {from && (
            <UserSection header="From" user={from} onEdit={handleEditFrom} />
          )}
        </Row>
        <Col className="text-end pe-2">
          <Button disabled={isSaving} onClick={handleSave}>
            {isSaving ? 'Saving' : 'Save'}
          </Button>
        </Col>
      </Form>
      {modalEditBilledTo}
      {modalEditFrom}
    </>
  )
}

export default EditInvoice
