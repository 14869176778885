import styles from '../styles/styles.module.css'
import { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Spinner,
  Modal,
  Image,
  Button,
  Stack,
  Offcanvas,
  Badge
} from 'react-bootstrap'
import {
  getProjectDataWithAllQuotes,
  getEngagedQuoteStatus
} from '../services/buyerServices'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import * as constants from '../constants'
import ProgressBarQuote from '../components/ProgressBarQuote'
import StepSupplierSampleNonDeco from '../components/StepSupplierSampleNonDeco'
import StepSupplierDesignReview from '../components/StepSupplierDesignReview'
import StepSupplierSamplePreProduction from '../components/StepSupplierSamplePreProduction'
import iconCheckGreen from '../images/icons/checkGreen.png'
import StepBuyerPurchaseOrder from '../components/StepBuyerPurchaseOrder'
import QuoteCard from '../components/QuoteCard'
import { QuoteSubmitForm } from '../components/QuoteSubmitForm'
import { ProjectDetails } from '../components/ProjectDetails'
import { useToast } from '../ToastContext'
import useQuote from '../hooks/useQuote'
import { createOrFetchInvoice } from '../services/invoiceService'

export const ProjectProgressSupplier = () => {
  const { addToast } = useToast()
  const navigate = useNavigate()
  const { projectId } = useParams()
  const [searchParams] = useSearchParams()
  const quoteData = useQuote(searchParams.get('quoteId'))
  const quoteAdjustedData = useQuote(searchParams.get('adjustedQuoteId'))

  const [loading, setLoading] = useState(true)
  const [stateChanged, setStateChanged] = useState(false)
  const [adjustedQuoteStateChanged, setAdjustedQuoteStateChanged] =
    useState(false)
  const [projectData, setProjectData] = useState(null)
  const [engagedQuoteStatusHistory, setEngagedQuoteStatusHistory] = useState([])

  const [currentStep, setCurrentStep] = useState(-1)

  // Modal
  const [showStepCompletionModal, setShowStepCompletionModal] = useState(false)
  const [showProjectDetailsModal, setShowProjectDetailsModal] = useState(false)
  const [showSelectedQuoteModal, setShowSelectedQuoteModal] = useState(false)

  // offcanvas properties
  const [showEditQuote, setShowEditQuote] = useState(false)
  const handleEditQuoteClose = () => {
    setShowEditQuote(false)
  }
  const toggleShowEditQuote = () => setShowEditQuote(s => !s)

  useEffect(() => {
    async function engagedProjectFetchData() {
      try {
        setProjectData(null)
        const response = await getProjectDataWithAllQuotes(projectId)
        setProjectData(response)
      } catch (error) {
        console.log(error)
      }
    }
    if (projectId) {
      engagedProjectFetchData()
    }
  }, [projectId, stateChanged])

  useEffect(() => {
    async function engagedQuoteFetchData() {
      try {
        const response = await getEngagedQuoteStatus(quoteData?.id)
        setEngagedQuoteStatusHistory(response)
      } catch (error) {
        console.log(error)
      }
    }
    if (quoteData) {
      engagedQuoteFetchData()
    }
  }, [quoteData, stateChanged])

  useEffect(() => {
    async function configureCurrentStep() {
      if (projectData || engagedQuoteStatusHistory.length > 0) {
        if (
          quoteData?.status === constants.STATUS_QUOTE_SELECTED &&
          (engagedQuoteStatusHistory.length === 0 ||
            engagedQuoteStatusHistory[0]?.status ===
            constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_REQUESTED ||
            engagedQuoteStatusHistory[0]?.status ===
            constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_SENT ||
            engagedQuoteStatusHistory[0]?.status ===
            constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_DELIVERED ||
            engagedQuoteStatusHistory[0]?.status ===
            constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_ADJUST ||
            engagedQuoteStatusHistory[0]?.status ===
            constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_ADJUSTED_QUOTE_SELECTED)
        ) {
          setCurrentStep(1) // non-deco sample
        } else if (
          engagedQuoteStatusHistory[0]?.status ===
          constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_COMPLETE ||
          engagedQuoteStatusHistory[0]?.status ===
          constants.STATUS_ENGAGED_QUOTE_DESIGN_SUBMITTED_FOR_REVIEW
        ) {
          setCurrentStep(2) // design review
        } else if (
          engagedQuoteStatusHistory[0]?.status ===
          constants.STATUS_ENGAGED_QUOTE_DESIGN_REVIEW_COMPLETE ||
          engagedQuoteStatusHistory[0]?.status ===
          constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_REQUESTED ||
          engagedQuoteStatusHistory[0]?.status ===
          constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_SENT ||
          engagedQuoteStatusHistory[0]?.status ===
          constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_DELIVERED ||
          engagedQuoteStatusHistory[0]?.status ===
          constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_ADJUST ||
          engagedQuoteStatusHistory[0]?.status ===
          constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_ADJUSTED_QUOTE_SELECTED
        ) {
          setCurrentStep(3) // pre-production sample
        } else if (
          engagedQuoteStatusHistory[0]?.status ===
          constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_COMPLETE
        ) {
          const invoiceId = await createOrFetchInvoice(quoteData.id)

          navigate('/invoice/' + invoiceId)
        } else {
          setCurrentStep(-1) // invalid state
        }
      } else {
        setCurrentStep(-1) // invalid state
      }
    }

    configureCurrentStep()
  }, [projectData, quoteData, engagedQuoteStatusHistory])

  useEffect(() => {
    if (!(projectData && currentStep !== -1)) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [projectData, currentStep])

  const handleGoToNextStep = e => {
    setShowStepCompletionModal(true)
  }

  const handleAdjustedQuoteSubmittedFromChild = e => {
    setAdjustedQuoteStateChanged(!adjustedQuoteStateChanged)
  }

  function getUserFriendlyStepName(stepNumber) {
    // by default, the supplier step starts at 1
    if (stepNumber === 1) return 'Non-deco sample'
    if (stepNumber === 2) return 'Design review'
    if (stepNumber === 3) return 'Pre-production sample'
    if (stepNumber === 4) return 'Purchase order'
  }

  function isQuoteAdjustEligible() {
    if (
      (currentStep === 1 &&
        engagedQuoteStatusHistory[0]?.status ===
        constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_DELIVERED) ||
      (currentStep === 3 &&
        engagedQuoteStatusHistory[0]?.status ===
        constants.STATUS_ENGAGED_QUOTE_SAMPLE_DECO_DELIVERED)
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <Container fluid={true} className="px-3 py-3 position-absolute">
        <Modal show={showStepCompletionModal} centered>
          <Modal.Header className="py-3">
            <Modal.Title>Congratulations!</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <div className="py-2">
              <Image src={iconCheckGreen} width={50} />
            </div>
            <div className="py-3">
              You have successfully completed the{' '}
              {getUserFriendlyStepName(currentStep)} step.
            </div>
            <Row className="py-2 px-5">
              <Stack direction="vertical" gap={2}>
                <Button
                  className={styles.button + ' py-2'}
                  size="sm"
                  onClick={() => {
                    setStateChanged(!stateChanged)
                    setShowStepCompletionModal(false)
                  }}
                >
                  Proceed
                </Button>
              </Stack>
            </Row>
          </Modal.Body>
        </Modal>
        <Modal
          show={showProjectDetailsModal}
          size="xl"
          fullscreen={false}
          centered
          onHide={() => setShowProjectDetailsModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Project details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProjectDetails project={projectData} allowQuoteSubmit={false} />
          </Modal.Body>
        </Modal>
        <Modal
          show={showSelectedQuoteModal}
          size="xl"
          fullscreen={false}
          centered
          onHide={() => setShowSelectedQuoteModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Selected quote</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {quoteAdjustedData ? (
              <Row>
                <Col>
                  <Row className="pb-2">
                    <Col>
                      <Badge bg="success">Currently Selected</Badge>
                    </Col>
                  </Row>
                  <QuoteCard quoteId={quoteData?.id} />
                </Col>
                <Col>
                  <Row className="pb-2">
                    <Col>
                      <Badge bg="warning">Adjusted</Badge>
                    </Col>
                  </Row>
                  <QuoteCard quoteId={quoteAdjustedData.id} />
                </Col>
              </Row>
            ) : (
              <QuoteCard quoteId={quoteData?.id} />
            )}
          </Modal.Body>
        </Modal>
        <Offcanvas
          show={showEditQuote}
          placement="end"
          onHide={handleEditQuoteClose}
          scroll={true}
          backdrop={false}
          className="w-75"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Quote adjustment</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <QuoteSubmitForm
              iteration={true}
              projectId={projectId}
              acceptLargerQuantityQuotes={
                projectData?.acceptLargerQueantityQuotes
              }
              initOrderQuantity={projectData?.initOrderQuantity}
              designFlexibility={projectData?.designFlexibility}
              prevSubmittedQuote={quoteData}
              sendToParentAdjustedQuoteSubmitted={
                handleAdjustedQuoteSubmittedFromChild
              }
            //sendToParentQuoteSubmitted={handleQuoteSubmittedFromChild}
            //sendToParentDraftQuoteSaved={handleDraftQuoteSavedFromChild}
            />
          </Offcanvas.Body>
        </Offcanvas>
        {/*<Offcanvas show={showMsgCanvas} placement='end' onHide={handleMsgCanvasClose} scroll={true} backdrop={false} className="w-50">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Messages</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <Messages 
                    title={"Please enter comments, questions, and feedback."} 
                    buttonLabel={"Submit"}
                    quoteId={quoteData?.id} 
                    type={constants.TYPE_MSG_QUOTE_COMMENTS}
                    numberOfLines={3}
                />
                </Offcanvas.Body>
            </Offcanvas>*/}
        <Row className="px-3" id={'projectProgressMainDiv'}>
          {loading ? (
            <Col xs={12}>
              <Row className="mx-3 my-3">
                Loading ...
                <Spinner
                  animation="border"
                  variant="secondary"
                  role="status"
                  className="mx-3"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </Row>
            </Col>
          ) : (
            <Col xs={12}>
              <Row>
                <ProgressBarQuote currentStep={currentStep} />
              </Row>
              {projectData && (
                <Row className="pb-3">
                  <Col className="text-end">
                    <Button
                      className={styles.buttonOutline + ' py-2'}
                      size="sm"
                      onClick={() => setShowProjectDetailsModal(true)}
                    >
                      Project details
                    </Button>
                    <Button
                      className={styles.buttonOutline + ' py-2 ms-2'}
                      size="sm"
                      onClick={() => setShowSelectedQuoteModal(true)}
                    >
                      Submitted quote {quoteAdjustedData ? '(2)' : ''}
                    </Button>
                    {isQuoteAdjustEligible() && (
                      <Button
                        className={styles.buttonOutline + ' py-2 ms-2'}
                        size="sm"
                        onClick={toggleShowEditQuote}
                        disabled={false}
                      >
                        Adjust quote
                      </Button>
                    )}
                  </Col>
                </Row>
              )}
              <Row>
                {projectId ? (
                  <>
                    {currentStep === 1 && ( // non-deco sample - default starting step
                      <StepSupplierSampleNonDeco
                        type={'supplier'}
                        projectId={projectId}
                        quoteData={quoteData}
                        sendToParentStepCompleted={handleGoToNextStep}
                      />
                    )}
                    {currentStep === 2 && ( // design review
                      <StepSupplierDesignReview
                        projectId={projectId}
                        quoteData={quoteData}
                        sendToParentStepCompleted={handleGoToNextStep}
                      />
                    )}
                    {currentStep === 3 && ( // pre-production sample
                      <StepSupplierSamplePreProduction
                        projectId={projectId}
                        quoteData={quoteData}
                        sendToParentStepCompleted={handleGoToNextStep}
                      />
                    )}
                    {currentStep === 4 && ( // purchase order
                      <StepBuyerPurchaseOrder
                        project={projectData}
                        quote={quoteData}
                        sendToParentStepCompleted={handleGoToNextStep}
                      />
                    )}
                  </>
                ) : (
                  <Row className="px-3 py-3">
                    <h6>
                      Something went wrong. Please select your quote again or
                      contact Package Maven.
                    </h6>
                  </Row>
                )}
              </Row>
            </Col>
          )}
        </Row>
        <div>
          <Col></Col>
        </div>
      </Container>
    </>
  )
}
