import { DocumentReference, Timestamp } from 'firebase/firestore'
import { FieldValue } from 'react-hook-form'

export type NewsPost = {
  id: string
  title: string
  contentHtml: string
  imageUrl?: string
  date: Date
  slug: string
}

export type DemoRequestForm = {
  firstName: string
  lastName: string
  email: string
  companyName: string
}

export type DemoRequest = DemoRequestForm & {
  id: string
  createdOn: Timestamp
  status: DemoRequestStatus
}

export enum DemoRequestStatus {
  Pending = 'Pending',
  InProgress = 'In Progress',
  Completed = 'Completed'
}

export type User = {
  company: string
  email: string
  phone: string
  location: string | undefined
  officeAddress: Address | undefined
  role: {
    admin: boolean
    supplier: boolean
    buyer: boolean
  }
}

export enum Courier {
  FedEx = 'FedEx',
  UPS = 'UPS',
  DHL = 'DHL'
}

export type Project = {
  id: string
  deliveryDate: Timestamp
  createdBy: string
  receivingLocation: string
}

export type Quote = {
  id: string
  unitCost: UnitCost[]
  oneTimeCost: OnetimeCost[]
  status: string
  moq: number
  projectRef: DocumentReference<Project>
  createdBy: string
  previousQuoteHistory: QuoteHistory[]
}

export type QuoteHistory = {
  index: number
  moq?: number
  unitCost: UnitCost[]
  oneTimeCost: OnetimeCost[]
}

export type UnitCost = {
  price: number
  category: string
  id: string
  onOffOption?: boolean
  moq?: number
}

export type OnetimeCost = {
  category: string
  description: string
  id: string
  price: number
}

export type Address = {
  address1: string
  address2?: string
  city: string
  state: string
  zip: string
}

export type InvoiceData = {
  invoiceNumber: number
  quoteRef: DocumentReference<Quote>
  createdAt: Timestamp
  editedCosts: OnetimeCost[]
  signatures: {
    buyer?: Signature
    supplier?: Signature
  }
  status?: InvoiceStatus
  from?: User
  billedTo?: User
}

export enum InvoiceStatus {
  SupplierReviewCompleted = 'SupplierReviewCompleted'
}

export type Invoice = InvoiceData & { id: string }

export type Signature = {
  svg: string
  signedAt: Timestamp | FieldValue<Timestamp>
}
