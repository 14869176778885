import { OnetimeCost, Quote, UnitCost } from '../types'

export function getQuotesWithStatus(
  quotes: Quote[] | undefined,
  status: string
) {
  return (quotes ?? []).filter(q => q.status === status)
}

export function getSubtotal(quote: Quote): number {
  const unitCosts = getUnitCosts(quote)
  const oneTimeCosts = getOneTimeCosts(quote)

  return (
    unitCosts.reduce((sum: number, item: UnitCost) => {
      return sum + item.price * (item.moq ?? quote.moq)
    }, 0) +
    oneTimeCosts.reduce((sum: number, item: { price: number }) => {
      return sum + item.price
    }, 0)
  )
}

export function getUnitCosts(quote: Quote): UnitCost[] {
  let result: UnitCost[] = []

  const quoteHistories = quote.previousQuoteHistory ?? []
  quoteHistories.forEach(quoteHistory => {
    if ((quoteHistory.unitCost ?? []).length > 0) {
      result = result.concat(
        quoteHistory.unitCost.map(item => {
          return { ...item, moq: quoteHistory.moq }
        })
      )
    }
  })

  result = result.concat(
    quote.unitCost.map(item => {
      return { ...item, moq: quote.moq }
    })
  )

  return result.filter(item => !!item.price)
}

export function getOneTimeCosts(quote: Quote): OnetimeCost[] {
  if (!quote) return []

  let result: OnetimeCost[] = []

  const quoteHistories = quote.previousQuoteHistory ?? []
  quoteHistories.forEach(quoteHistory => {
    if ((quoteHistory.oneTimeCost ?? []).length > 0) {
      result = result.concat(
        quoteHistory.oneTimeCost.map(item => {
          return { ...item, moq: quoteHistory.moq }
        })
      )
    }
  })

  result = result.concat(
    quote.oneTimeCost.map(item => {
      return { ...item }
    })
  )

  return result.filter(item => !!item.price)
}
