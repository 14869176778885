import { Address } from '../types'

export function formatAddress(address: Address): string {
  return [
    address.address1,
    address.address2,
    address.city,
    address.state,
    address.zip
  ]
    .filter(component => !!component)
    .join(' ')
}
