import { createContext, useContext, useState } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'
import logo from './images/logo_only.png'

const ToastContext = createContext()

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([])

  const addToast = (message, variant = 'success') => {
    window.scrollTo(0, 0)
    setToasts(prevToasts => [
      ...prevToasts,
      { id: Date.now(), message, variant }
    ])
  }

  const removeToast = id => {
    setToasts(prevToasts => prevToasts.filter(t => t.id !== id))
  }

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer position="top-end" className="p-3 position-fixed">
        {toasts.map(toast => (
          <Toast
            key={toast.id}
            onClose={() => removeToast(toast.id)}
            autohide
            delay={3000}
            bg={toast.variant.toLowerCase()}
          >
            <Toast.Header className="bg-light">
              <img src={logo} width={25} className="rounded me-2" alt="" />
              <strong className="me-auto">Package Maven</strong>
            </Toast.Header>
            <Toast.Body className={'text-white'}>{toast.message}</Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
    </ToastContext.Provider>
  )
}

export const useToast = () => useContext(ToastContext)
