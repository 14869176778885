import styles from '../styles/styles.module.css'
import { useRef, useMemo, useState } from 'react'
import { useToast } from '../ToastContext'
import { Container, Row, Col, Stack, Button, Offcanvas } from 'react-bootstrap'
import { formatPrice } from '../utils/price'
import { formatDate } from '../utils/timestamp'
import { getOneTimeCosts, getUnitCosts } from '../utils/quote'
import html2pdf from 'html2pdf.js'
import useUser from '../hooks/useUser'
import { useParams } from 'react-router-dom'
import useInvoice from '../hooks/useInvoice'
import ProgressBarQuote from '../components/ProgressBarQuote'
import { formatInvoiceNumber } from '../utils/invoice'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../firebase'
import ProgressBarProject from '../components/ProgressBarProject'
import EditInvoice from '../components/EditInvoice'
import { Invoice, InvoiceStatus, Signature, UnitCost } from '../types'
import { updateInvoice } from '../services/invoiceService'
import useInvoiceSubtotal from '../hooks/useInvoiceSubtotal'
import useSignature from '../hooks/useSignature'
import { serverTimestamp, Timestamp } from 'firebase/firestore'
import UserSection from '../components/UserSection'
import useQuote from '../hooks/useQuote'
import useProject from '../hooks/useProject'

export default function InvoicePage() {
  const { addToast } = useToast()
  const [user] = useAuthState(auth)
  const userData = useUser(user?.email)
  const { invoiceId } = useParams()
  const pdfRef = useRef<HTMLDivElement>(null)
  const { invoice, invalidateInvoice } = useInvoice(invoiceId)
  const quote = useQuote(invoice?.quoteRef.id)
  const project = useProject(quote?.projectRef.id)

  const isSupplier = userData?.role.supplier === true
  const [showEditInvoice, setShowEditInvoice] = useState(false)

  const unitCosts = useMemo(() => {
    if (!quote) return []

    return getUnitCosts(quote)
  }, [quote])
  const oneTimeCosts = useMemo(() => {
    if (!quote) return []

    return getOneTimeCosts(quote)
  }, [quote])

  const buyer = useUser(project?.createdBy)
  const supplier = useUser(quote?.createdBy)
  const billedTo = invoice?.billedTo ?? buyer
  const from = invoice?.from ?? supplier
  const subtotal = useInvoiceSubtotal(invoice)
  const { modal, setShowSignatureModal } = useSignature({
    onConfirm: async signatureSvg => {
      if (!invoice) return

      try {
        if (isSupplier) {
          await updateInvoice({
            ...invoice,
            signatures: {
              ...invoice.signatures,
              supplier: {
                svg: signatureSvg,
                signedAt: serverTimestamp()
              }
            }
          })
        } else {
          await updateInvoice({
            ...invoice,
            signatures: {
              ...invoice.signatures,
              buyer: {
                svg: signatureSvg,
                signedAt: serverTimestamp()
              }
            }
          })
        }

        invalidateInvoice()
        addToast('Signed successfully', 'success')
      } catch (error) {
        addToast('Failed to update invoice', 'danger')
      }
    }
  })

  const handleDownload = () => {
    const element = pdfRef.current
    if (!element || invoice === undefined) {
      return
    }

    element.classList.add('pdf-mode')
    const options = {
      margin: 0.5,
      filename: `PurchaseOrder-#${formatInvoiceNumber(invoice.invoiceNumber)}.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
      pagebreak: { mode: 'avoid-all' }
    }
    if (element)
      html2pdf(element, options).then(() => {
        element.classList.remove('pdf-mode')
      })
  }

  const handleEdit = () => {
    if (!isSupplier) {
      return
    }

    setShowEditInvoice(true)
  }

  const handleCloseEditInvoice = () => {
    if (!isSupplier) {
      return
    }

    setShowEditInvoice(false)
  }

  const handleEditInvoice = async (editedInvoice: Invoice) => {
    if (!isSupplier) {
      return
    }

    try {
      await updateInvoice(editedInvoice)

      addToast('Invoice updated successfully', 'success')

      invalidateInvoice()
      setShowEditInvoice(false)
    } catch (error) {
      console.error('Error updating invoice:', error)
      addToast('Failed to update invoice', 'danger')
    }
  }

  const handleConfirm = () => {
    setShowSignatureModal(true)
  }

  const handleCompleteReview = async () => {
    if (!invoice) {
      return
    }

    const userConfirmed = window.confirm(
      'Are you sure you want to complete the review?'
    )

    if (!userConfirmed) {
      return
    }

    try {
      await updateInvoice({
        ...invoice,
        status: InvoiceStatus.SupplierReviewCompleted
      })

      invalidateInvoice()
      addToast('Review completed successfully', 'success')
    } catch (error) {
      console.error('Error updating invoice:', error)
      addToast('Failed to update invoice', 'danger')
    }
  }

  if (!quote || !project || !invoice) {
    return <div>Loading...</div>
  }

  const deliveryDate = project.deliveryDate
    ? formatDate(project.deliveryDate)
    : undefined
  const quantity = quote.moq
  const tariff = undefined // todo: tarifff?
  const total = subtotal // todo: how to calculate total?
  const editedCosts = invoice.editedCosts ?? []

  return (
    <>
      <Container className="my-5 pb-5">
        <Row>
          {isSupplier ? (
            <ProgressBarQuote currentStep={4} />
          ) : (
            <ProgressBarProject currentStep={6} />
          )}
        </Row>
        <Container className="px-5 purchaseOrder my-3" ref={pdfRef}>
          <Row className="header px-3 py-3 align-items-center">
            <Col className="px-0">
              <div className="title">Purchase order</div>
            </Col>
            {isSupplier &&
              invoice.status !== InvoiceStatus.SupplierReviewCompleted && (
                <Col className="px-0 text-end gap-3 no-print">
                  <Button className="btn-secondary" onClick={handleEdit}>
                    Edit
                  </Button>
                  <Button className="ms-2" onClick={handleCompleteReview}>
                    Complete Review
                  </Button>
                </Col>
              )}
          </Row>
          <Row className="ps-3 py-4 align-items-center">
            <Col lg={5}>
              <Row className="align-items-center">
                <Col className="fw-bold fs-5">
                  Invoice
                  <br />#{formatInvoiceNumber(invoice.invoiceNumber)}
                </Col>
                <Col>
                  <div className="fs-6 text-secondary mb-1">Invoice Date</div>
                  <div className="invoiceDate d-inline">
                    {formatDate(invoice.createdAt)}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="px-0 text-end">
              <Button className="no-print" onClick={handleDownload}>
                Download
              </Button>
            </Col>
          </Row>
          <Row className="pt-3 row-in-pdf gap-4">
            <Col className="px-0">
              <Stack gap={4}>
                <table>
                  <thead className="header">
                    <tr>
                      <th className="px-2 py-2">Item</th>
                      <th className="px-2 py-2 right">QUANTITY</th>
                      <th className="px-2 py-2 right">Unit PRICE (USD)</th>
                      <th className="px-2 py-2 right">Amount (USD)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {unitCosts.map((item, index) => (
                      <tr key={index}>
                        <td className="px-2 py-2">{item.category}</td>
                        <td className="px-2 py-2 right">{item.moq}</td>
                        <td className="px-2 py-2 right">
                          {formatPrice(item.price)}
                        </td>
                        <td className="px-2 py-2 right">
                          {formatPrice(item.price * (item.moq ?? quantity))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table>
                  <thead className="header">
                    <tr>
                      <th className="px-2 py-2">One time costs</th>
                      <th className="px-2 py-2 right">Amount (USD)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {oneTimeCosts.map((item, index) => (
                      <tr key={index}>
                        <td className="px-2 py-2">{item.category}</td>
                        <td className="px-2 py-2 right">
                          {formatPrice(item.price)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table>
                  <thead className="header">
                    <tr>
                      <th className="px-2 py-2">Adjusted Costs</th>
                      <th className="px-2 py-2 right">Amount (USD)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {editedCosts.map((item, index) => (
                      <tr key={index}>
                        <td className="px-2 py-2">{item.category}</td>
                        <td className="px-2 py-2 right">
                          {formatPrice(item.price)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table className="prices">
                  <tbody>
                    <tr>
                      <td className="px-2 py-2 type">
                        Subtotal
                        <br />
                        <span style={{ fontStyle: 'italic' }}>
                          (= Moq * Unit cost + One-time cost + Adjusted cost)
                        </span>
                      </td>
                      <td className="px-2 py-2 right">
                        {formatPrice(subtotal)}
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="px-2 py-2 type">Tariff</td>
                      <td className="px-2 py-2 right">
                        {tariff === undefined ? 'N/A' : formatPrice(tariff)}
                      </td>
                    </tr> */}
                    <tr>
                      <td className="px-2 py-2 type">Total</td>
                      <td className="px-2 py-2 right fw-bold">
                        {formatPrice(total)}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="page-break"></div>
                <table className="terms">
                  <thead className="header">
                    <tr>
                      <th className="px-2 py-2">TERMS AND CONDITIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-2 py-3">
                        Intellectual Property (IP) Rights: Handling of any IP
                        related to the samples, particularly if custom-made.
                        <br />
                        <br />
                        Confidentiality Agreement: Clause ensuring supplier
                        confidentiality regarding sample design or proprietary
                        information.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="terms">
                  <thead className="header">
                    <tr>
                      <th className="px-2 py-2">ACKNOWLEDGEMENT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-2 py-3">
                        Supplier’s Acknowledgment: Section for the supplier to
                        confirm receipt of the purchase order and acceptance of
                        the order terms.
                        <br />
                        <br />
                        Buyer’s Acknowledgment: Section for the buyer to confirm
                        agreement with the order terms and conditions, and to
                        authorize the proceeding with the order.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="terms">
                  <thead className="header">
                    <tr>
                      <th className="px-2 py-2" colSpan={2}>
                        DELIVERY INFORMATION
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-2 py-2 type">Required delivery date</td>
                      <td className="px-2 py-2 right">{deliveryDate}</td>
                    </tr>
                    <tr>
                      <td className="px-2 py-2 type">Receiving address</td>
                      <td className="px-2 py-2 right">
                        {project.receivingLocation}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Stack>
            </Col>
            <Col className="rightPanel pb-5">
              {/* Billed To Section */}
              <Row className="py-4 px-3">
                {billedTo && <UserSection header="Billed To" user={billedTo} />}
              </Row>

              {/* From Section */}
              <Row className="py-4 px-3 border-top">
                {from && <UserSection header="From" user={from} />}
              </Row>

              {/* Total Section */}
              <Row className="border-top py-4 px-3 mb-3">
                <Col>
                  <h5 className="fw-bold">Total</h5>
                </Col>
                <Col>
                  <h4 className="text-end fw-bold">{formatPrice(total)}</h4>
                </Col>
              </Row>
              <Row className="py-3 px-3 gap-4 border-top">
                <Col>
                  <h4 className="fw-bold">Signatures</h4>
                </Col>
                <SignatureSection
                  signature={invoice?.signatures?.buyer}
                  label="Buyer"
                  onSign={
                    !isSupplier &&
                    invoice.status === InvoiceStatus.SupplierReviewCompleted
                      ? handleConfirm
                      : undefined
                  }
                />
                <SignatureSection
                  signature={invoice?.signatures?.supplier}
                  label="Supplier"
                  placeholder={
                    isSupplier
                      ? invoice.status !== InvoiceStatus.SupplierReviewCompleted
                        ? 'Complete Review to Proceed'
                        : invoice?.signatures?.buyer === undefined
                          ? 'Supplier signs after the buyer.'
                          : undefined
                      : undefined
                  }
                  onSign={
                    isSupplier && invoice?.signatures?.buyer !== undefined
                      ? handleConfirm
                      : undefined
                  }
                />
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      {isSupplier && (
        <Offcanvas
          show={showEditInvoice}
          placement="end"
          onHide={handleCloseEditInvoice}
          scroll={true}
          backdrop={false}
          className="w-50"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Edit Invoice</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <EditInvoice invoice={invoice} onSave={handleEditInvoice} />
          </Offcanvas.Body>
        </Offcanvas>
      )}
      {modal}
    </>
  )
}

const SignatureSection = ({
  signature,
  label,
  placeholder,
  onSign
}: {
  signature?: Signature
  label: string
  placeholder?: string
  onSign?: () => void
}) => {
  const signatureHeight = 80

  return (
    <Row className="mx-0 gap-4">
      <Col>
        <Row>
          {signature ? (
            <div
              className="mt-1"
              style={{ width: '100%', height: `${signatureHeight}px` }}
              dangerouslySetInnerHTML={{
                __html: signature.svg.replace(
                  '<svg',
                  '<svg viewBox="0 0 448 150"'
                )
              }}
            />
          ) : (
            <div
              className="mt-1"
              style={{
                width: '100%',
                height: `${signatureHeight}px`,
                display: 'flex',
                alignItems: 'flex-end'
              }}
            >
              {onSign !== undefined ? (
                <Button
                  className="mb-2 mx-0 no-print"
                  style={{ width: '100%' }}
                  onClick={onSign}
                >
                  Sign
                </Button>
              ) : placeholder !== undefined ? (
                <div className="mb-2 fw-bold">{placeholder}</div>
              ) : undefined}
            </div>
          )}
          <div
            style={{
              width: '100%',
              height: '1px',
              backgroundColor: 'black'
            }}
          />
          <div className="mx-0 px-2">{label}</div>
        </Row>
      </Col>
      <Col>
        <Row>
          <div
            className="mt-1"
            style={{
              width: '100%',
              height: `${signatureHeight}px`,
              display: 'flex',
              alignItems: 'flex-end'
            }}
          >
            {signature && signature.signedAt instanceof Timestamp ? (
              <div className="mb-2 fs-3">{formatDate(signature.signedAt)}</div>
            ) : null}
          </div>

          <div
            style={{
              width: '100%',
              height: '1px',
              backgroundColor: 'black'
            }}
          />
          <div className="mx-0 px-2">Date</div>
        </Row>
      </Col>
    </Row>
  )
}
