import styles from '../styles/styles.module.css'
import { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Stack,
  Button,
  Modal,
  Spinner,
  Nav,
  ProgressBar,
  Accordion,
  Image
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getAllProjects, getAllQuotes } from '../services/expertServices'
import {
  updateProjectStatus,
  updateQuoteStatus
} from '../services/buyerServices'
import moment from 'moment'
import * as constants from '../constants'
import Messages from '../components/Messages'
import iconMessage from '../images/icons/communication.png'
import { sendNotification } from '../services/notificationServices'
import { ProjectDetails } from '../components/ProjectDetails'
import { useToast } from '../ToastContext'

export const InitialAssessment = () => {
  const { addToast } = useToast()
  const [projectsUnderReviewData, setProjectsUnderReviewData] = useState()
  //const [quotesUnderReviewData, setQuotesUnderReviewData] = useState();
  const [projectsStateChanged, setProjectsStateChanged] = useState(false)
  //const [quotesStateChanged, setQuotesStateChanged] = useState(false);
  const [projectsLoading, setProjectsLoading] = useState(true)
  //const [quotesLoading, setQuotesLoading] = useState(true);

  // Modal
  const [showSpinnerModal, setShowSpinnerModal] = useState(false)

  useEffect(() => {
    async function projectsUnderReviewFetchData() {
      try {
        const response = await getAllProjects(
          constants.STATUS_PROJECT_INIT_ASSESSMENT
        )
        setProjectsUnderReviewData(response)
      } catch (error) {
        console.log(error)
      }
    }
    projectsUnderReviewFetchData()
  }, [])

  useEffect(() => {
    async function projectsUnderReviewFetchData() {
      try {
        const response = await getAllProjects(
          constants.STATUS_PROJECT_INIT_ASSESSMENT
        )
        setProjectsUnderReviewData(response)
      } catch (error) {
        console.log(error)
      }
    }
    projectsUnderReviewFetchData()
  }, [projectsStateChanged])

  /*useEffect(() => {
        async function quotesUnderReviewFetchData() {
            try {
                const response = await getAllQuotes(constants.STATUS_QUOTE_INIT_ASSESSMENT);
                setQuotesUnderReviewData(response);
            } catch (error) {
                console.log(error);
            } 
        }
        quotesUnderReviewFetchData();
    }, []);

    useEffect(() => {
        async function quotesUnderReviewFetchData() {
            try {
                const response = await getAllQuotes(constants.STATUS_QUOTE_INIT_ASSESSMENT);
                setQuotesUnderReviewData(response);
            } catch (error) {
                console.log(error);
            } 
        }
        quotesUnderReviewFetchData();
    }, [quotesStateChanged]);*/

  useEffect(() => {
    if (!projectsUnderReviewData) {
      setProjectsLoading(true)
    } else {
      setProjectsLoading(false)
    }
  }, [projectsUnderReviewData])

  /*useEffect(() => {
        if (!quotesUnderReviewData) {
            setQuotesLoading(true);
        } else {
            setQuotesLoading(false);
        }
    }, [quotesUnderReviewData]);*/

  const handleProjectAssessmentComplete = async e => {
    setShowSpinnerModal(true)
    e.preventDefault()
    const projectId = e.currentTarget.getAttribute('data-project-id')

    try {
      updateProjectStatus(
        projectId,
        constants.STATUS_PROJECT_ACCEPTING_QUOTES
      ).then(() => {
        setProjectsStateChanged(true)
        addToast('Project successfully marked as accepting supplier quotes.', 'success')
        sendNotification(
          constants.NOTI_TYPE_BUYER_PROJECT_POSTED,
          projectId,
          null
        )
        sendNotification(
          constants.NOTI_TYPE_SUPPLIER_PROJECT_POSTED,
          projectId,
          null
        )
      })
    } catch {
      addToast('Project status update failed.', 'danger')
    }
    setShowSpinnerModal(false)
  }

  /*const handleQuoteAssessmentComplete = async (e) => {
    setShowSpinnerModal(true)
        e.preventDefault();
        const quoteId = e.currentTarget.getAttribute("data-quote-id");

        try {
            updateQuoteStatus(quoteId, constants.STATUS_QUOTE_SUBMITTED)
                .then(() => {
                    setQuotesStateChanged(true);
                    addToast('Quote successfully marked as submitted for buyer review.', 'success')
                });
        } catch {
            addToast('Quote status update failed.', 'danger')
        }
    setShowSpinnerModal(false)
    }*/

  return (
    <>
      <Container fluid={true}>
        <Row className="px-2 pt-3 pb-2">
          <Col>
            <h4 className="mb-3">Projects under review</h4>
          </Col>
        </Row>
        <Row className="px-2 pb-3">
          {projectsLoading ? (
            <Col xs={12} md={9} xxl={10}>
              <Row className="mx-3 my-3">
                Loading ...
                <Spinner
                  animation="border"
                  variant="secondary"
                  role="status"
                  className="mx-3"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </Row>
            </Col>
          ) : (
            <Col className="pb-3">
              {projectsUnderReviewData &&
                projectsUnderReviewData.length === 0 && (
                  <Card className="px-3 py-3">
                    <Col>
                      <div className={styles.cardListItemValue}>
                        There are no projects under review yet.
                      </div>
                    </Col>
                  </Card>
                )}
              {projectsUnderReviewData &&
                projectsUnderReviewData.map(data => (
                  <Accordion
                    className="mb-3"
                    key={projectsUnderReviewData.indexOf(data)}
                  >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <Stack direction="horizontal" gap={5}>
                          <h4>{data?.name}</h4>
                        </Stack>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row className="px-4">
                          <Col className="text-end">
                            <Button
                              className={styles.button + ' py-2'}
                              size="sm"
                              onClick={handleProjectAssessmentComplete}
                              data-project-id={data.id}
                            >
                              Complete
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          <ProjectDetails
                            project={data}
                            allowQuoteSubmit={false}
                          />
                        </Row>
                        <Row className="px-4 py-3">
                          <Card className="px-0">
                            <Card.Title className="px-3 pt-3 pb-2">
                              <Image src={iconMessage} />
                              <span className="ps-3">
                                Initial assessment feedback
                              </span>
                            </Card.Title>
                            <Card.Body className="pt-2">
                              <Messages
                                title={
                                  'Please submit any comments or questions.'
                                }
                                buttonLabel={'Submit'}
                                projectId={data?.id}
                                type={constants.TYPE_MSG_PRJ_INIT_ASESSMENT}
                                numberOfLines={3}
                              />
                            </Card.Body>
                          </Card>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
            </Col>
          )}
        </Row>
        {/*<Row className="px-2 pt-3 pb-2">
                <Col>
                    <h4 className="mb-3">Quotes under review</h4>
                </Col>
            </Row>
            <Row className='px-2 pb-3'>
                {quotesLoading ? (
                <Col xs={12} md={9} xxl={10}>
                    <Row className="mx-3 my-3">
                        Loading ... 
                        <Spinner animation="border" variant="secondary" role="status" className="mx-3">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </Row>
                </Col>
                ) : (
                <Col className="pb-3">
                    {quotesUnderReviewData && quotesUnderReviewData.length === 0 && (
                        <Card className='px-3 py-3'>
                            <Col>
                                <div className={styles.cardListItemValue}>
                                    There are no quotes under review yet. 
                                </div>
                            </Col>
                        </Card>
                    )}
                    {quotesUnderReviewData && quotesUnderReviewData.map(data => (
                    <Card className='mb-3' key={quotesUnderReviewData.indexOf(data)}>
                        <Row>
                            <Col md={12}>
                                <ListGroup className="list-group-flush">
                                    <ListGroup.Item>
                                        <Row className='pb-2'>
                                            <Col xs={12} md={10}>
                                                <Row>
                                                    <Col xs={6} md={3} lg={2}>
                                                        <Stack direction="vertical" gap={1} className='py-2'>
                                                            <div className={styles.cardListItemLabel}>Supplier</div>
                                                            <div className={styles.cardListItemValue}>{data?.supplierCompany}</div>
                                                        </Stack>
                                                    </Col>
                                                    <Col xs={6} md={3} lg={2}>
                                                        <Stack direction="vertical" gap={1} className='py-2'>
                                                            <div className={styles.cardListItemLabel}>Brand</div>
                                                            <div className={styles.cardListItemValue}>{data?.project?.brand}</div>
                                                        </Stack>
                                                    </Col>
                                                    <Col xs={6} md={3} lg={2}>
                                                        <Stack direction="vertical" gap={1} className='py-2'>
                                                            <div className={styles.cardListItemLabel}>Project name</div>
                                                            <div className={styles.cardListItemValue}>{data?.project?.name}</div>
                                                        </Stack>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} md={2}>
                                                <Stack direction="vertical" gap={1}>
                                                    <Button variant="outline-dark" size="sm">
                                                        <Nav.Link as={Link} 
                                                                to={constants.PAGE_PATH_PROJECT_DETAILS} 
                                                                state={JSON.stringify({project: data?.project, quote: null, allowQuoteSubmit: false, fromPage: constants.PAGE_PATH_INIT_ASSESSMENT})}>
                                                            Project details
                                                        </Nav.Link>
                                                    </Button>
                                                    <Button variant="outline-dark" size="sm" onClick={handleQuoteAssessmentComplete} data-quote-id={data.id}>
                                                        Complete
                                                    </Button>
                                                </Stack>
                                            </Col>
                                        </Row>
                                        <Row className='py-2 px-2'>
                                            <h5>Quote details</h5>
                                            <QuoteCard quoteData={data} />
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>                            
                            </Col>
                        </Row>                        
                    </Card>
                    ))}            
                </Col>
                )}
            </Row>*/}
        <Modal show={showSpinnerModal} fullscreen centered style={{ backgroundColor: 'transparent', opacity: '0.5' }}>
          <Modal.Body className="text-center align-content-center">
            <Spinner animation='border' role='status'>
              <span className='visually-hidden'>Loading ...</span>
            </Spinner>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  )
}
