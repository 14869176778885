import { Invoice } from '../types'
import { getSubtotal } from '../utils/quote'
import useQuote from './useQuote'

const useInvoiceSubtotal = (invoice: Invoice | undefined): number => {
  const quote = useQuote(invoice?.quoteRef.id)
  const editedCostsSubtotal =
    (invoice?.editedCosts ?? []).reduce(
      (sum: number, item: { price: number }) => {
        return sum + item.price
      },
      0
    ) ?? 0

  return quote ? getSubtotal(quote) + editedCostsSubtotal : 0
}

export default useInvoiceSubtotal
