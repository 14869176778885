import { useEffect, useState } from 'react'
import { getProjectData } from '../services/buyerServices'
import { Project } from '../types'

const useProject = (projectId: string | undefined) => {
  const [project, setProject] = useState<Project>()

  useEffect(() => {
    async function fetchProject(projectId: string) {
      try {
        const response = await getProjectData(projectId)
        setProject(response as Project)
      } catch (error) {
        console.log(error)
      }
    }

    if (projectId) {
      fetchProject(projectId)
    }
  }, [projectId, setProject])

  return project
}

export default useProject
