import { db } from '../firebase'
import {
  doc,
  getDoc,
  increment,
  runTransaction,
  serverTimestamp
} from 'firebase/firestore'
import { Invoice } from '../types'

export async function fetchInvoice(
  quoteId: string
): Promise<Invoice | undefined> {
  const invoiceRef = doc(db, 'invoices', quoteId) // Use quoteId as the invoice document ID
  const invoiceDoc = await getDoc(invoiceRef)

  if (invoiceDoc.exists()) {
    return {
      id: invoiceDoc.id,
      ...invoiceDoc.data()
    } as Invoice
  }

  return undefined
}

export async function createOrFetchInvoice(quoteId: string): Promise<string> {
  const quoteRef = doc(db, 'quotes', quoteId) // Reference to the quote document
  const invoiceRef = doc(db, 'invoices', quoteId) // Use quoteId as the invoice document ID
  const countersRef = doc(db, 'counters', 'invoices') // Counter document reference

  try {
    // Attempt to fetch an existing invoice
    const existingInvoice = await fetchInvoice(quoteId)

    if (existingInvoice) {
      // If an invoice exists, return its document ID
      return invoiceRef.id
    }

    // If no invoice exists, create a new one
    await runTransaction(db, async transaction => {
      // Check if the quote exists
      const quoteDoc = await transaction.get(quoteRef)
      if (!quoteDoc.exists()) {
        throw new Error(`Quote with ID ${quoteId} does not exist.`)
      }

      // Fetch and increment the invoice counter
      const countersDoc = await transaction.get(countersRef)
      let newInvoiceNumber = 1 // Default invoice number if no counter exists

      if (countersDoc.exists()) {
        newInvoiceNumber = countersDoc.data().lastInvoiceNumber + 1
        transaction.update(countersRef, { lastInvoiceNumber: increment(1) })
      } else {
        // Initialize the counter document if it doesn't exist
        transaction.set(countersRef, { lastInvoiceNumber: newInvoiceNumber })
      }

      // Create a new invoice document
      const newInvoice = {
        quoteRef,
        invoiceNumber: newInvoiceNumber,
        createdAt: serverTimestamp()
      }
      transaction.set(invoiceRef, newInvoice)
    })

    return invoiceRef.id // Return the document ID of the newly created invoice
  } catch (error) {
    console.error('Error creating or fetching invoice:', error)
    throw error
  }
}

export async function getInvoice(invoiceId: string): Promise<Invoice> {
  try {
    // Reference to the invoice document
    const invoiceRef = doc(db, 'invoices', invoiceId)

    // Fetch the document
    const invoiceDoc = await getDoc(invoiceRef)

    if (!invoiceDoc.exists()) {
      throw new Error(`Invoice with ID ${invoiceId} does not exist.`)
    }

    // Return the invoice data
    return {
      id: invoiceDoc.id, // Include the document ID
      ...invoiceDoc.data() // Spread the document fields
    } as Invoice
  } catch (error) {
    console.error('Error fetching invoice:', error)
    throw error
  }
}

export async function updateInvoice(invoice: Invoice): Promise<void> {
  try {
    // Reference to the invoice document
    const invoiceRef = doc(db, 'invoices', invoice.id)

    // Update the document
    await runTransaction(db, async transaction => {
      transaction.set(invoiceRef, invoice)
    })
  } catch (error) {
    console.error('Error updating invoice:', error)
    throw error
  }
}
