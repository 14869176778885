import styles from '../styles/styles.module.css'
import { useEffect, useState, useRef } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  Stack,
  Button,
  Form,
  Accordion,
  Image,
  Alert,
  Modal,
  Spinner
} from 'react-bootstrap'
import {
  setEngagedQuoteStatus,
  getEngagedQuoteStatus
} from '../services/buyerServices'
import { getFile } from '../services/fileStorageServices'
import { sendNotification } from '../services/notificationServices'
import Messages from './Messages'
import * as constants from '../constants'
import iconMessage from '../images/icons/communication.png'
import iconCheckGreen from '../images/icons/checkGreen.png'
import iconInstruction from '../images/icons/instruction.png'
import { FileGallery } from '../components/FileGallery'
import { useToast } from '../ToastContext'

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
// Import the File Type Validation plugin
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
)

export default function StepBuyerDesignReview(props) {
  const { addToast } = useToast()
  const projectId = props?.projectId
  const quoteData = props?.quoteData

  const [stateChanged, setStateChanged] = useState(false)
  const [engagedQuoteStatusHistory, setEngagedQuoteStatusHistory] = useState([])

  const designFilesSubmitBtnRef = useRef()
  const designFilesUpdateBtnRef = useRef()
  const designFilesSubmitProceedBtnRef = useRef()
  const [filesRefData, setFilesRefData] = useState(null)
  const [designFiles, setDesignFiles] = useState([])
  const [newDesignFiles, setNewDesignFiles] = useState([])
  const [uploadNewDesignFiles, setUploadNewDesignFiles] = useState(false)

  // Modal
  const [showCompletionModal, setShowCompletionModal] = useState(false)
  const [showSpinnerModal, setShowSpinnerModal] = useState(false)

  useEffect(() => {
    async function engagedQuoteFetchData() {
      try {
        setEngagedQuoteStatusHistory([])
        const response = await getEngagedQuoteStatus(quoteData?.id)
        setEngagedQuoteStatusHistory(response)
      } catch (error) {
        console.log(error)
      }
    }
    engagedQuoteFetchData()
  }, [quoteData, stateChanged])

  useEffect(() => {
    if (engagedQuoteStatusHistory && engagedQuoteStatusHistory.length > 0) {
      if (
        engagedQuoteStatusHistory[0]?.status ===
        constants.STATUS_ENGAGED_QUOTE_DESIGN_SUBMITTED_FOR_REVIEW
      ) {
        setFilesRefData(engagedQuoteStatusHistory[0]?.values?.designFileRefData)
      }
    }
  }, [engagedQuoteStatusHistory])

  useEffect(() => {
    async function designFilesFetchData() {
      try {
        if (filesRefData) {
          var files = []
          for (const data of filesRefData) {
            const response = await getFile(data.blobName)
            const fileExt = data?.blobName
              ?.substring(data?.blobName?.lastIndexOf('.') + 1)
              ?.toLowerCase()
            const base64String = btoa(
              new Uint8Array(response.data[0].data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            )
            const fileObj = {
              base64String: base64String,
              fileExt: fileExt
            }
            files.push(fileObj)
          }
          setDesignFiles(files)
        }
      } catch (error) {
        console.log(error)
      }
    }
    designFilesFetchData()
  }, [filesRefData])

  useEffect(() => {
    if (designFiles && designFiles.length > 0) {
      setUploadNewDesignFiles(false)
    } else {
      setUploadNewDesignFiles(true)
    }
  }, [designFiles])

  const onSubmitDesign = async e => {
    if (!newDesignFiles || newDesignFiles.length === 0) {
      addToast('Please attach design files to submit.', 'danger')
    } else {
      setShowCompletionModal(true)
    }
  }

  const onProceedSubmitDesign = async e => {
    setShowSpinnerModal(true)
    e.preventDefault()
    if (designFilesSubmitProceedBtnRef.current) {
      designFilesSubmitProceedBtnRef.current.setAttribute('disabled', 'disabled')

      try {
        await setEngagedQuoteStatus(
          quoteData?.id,
          constants.STATUS_ENGAGED_QUOTE_DESIGN_SUBMITTED_FOR_REVIEW,
          { designFiles: newDesignFiles }
        )
        setShowCompletionModal(false)
        addToast('Design files submitted successfully.', 'success')
        setStateChanged(!stateChanged)
        sendNotification(
          constants.NOTI_TYPE_SUPPLIER_DESIGN_READY_FOR_REVIEW,
          projectId,
          quoteData?.id
        )
        sendNotification(
          constants.NOTI_TYPE_BUYER_DESIGN_SUBMITTED,
          projectId,
          quoteData?.id
        )
      } catch {
        addToast('Submitting design files failed, please try again.', 'danger')
      }

      designFilesSubmitProceedBtnRef.current.removeAttribute('disabled')
      setShowSpinnerModal(false)
    }
  }

  return (
    <Container fluid className="px-2">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <Stack direction="horizontal" gap={5}>
              <h4>Design review</h4>
            </Stack>
          </Accordion.Header>
          <Accordion.Body>
            <Row className="px-2 justify-content-md-center">
              <Col className="py-2 px-1">
                <Alert className={styles.instructionBackgroundColor + ' my-2'}>
                  <Row className='className="justify-content-md-center py-2'>
                    <Col xs={2} lg={2} className="text-end">
                      <div className="mb-2">
                        <Image src={iconInstruction} />
                      </div>
                    </Col>
                    <Col xs={10} lg={9}>
                      <p style={{ fontWeight: 'bold' }}>What's next?</p>
                      <p>
                        Let's finalize your packaging design before requesting
                        decorated samples.
                      </p>
                      <p>
                        <span style={{ fontWeight: 'bold' }}>Step 1.</span>{' '}
                        Submit your designs.
                        <br />
                        <span style={{ fontWeight: 'bold' }}>Step 2.</span>{' '}
                        Supplier reviews and provide feedback.
                        <br />
                        <span style={{ fontWeight: 'bold' }}>Step 3.</span> Make
                        design adjustments if needed.
                      </p>
                      <p className="mb-0">
                        If you have any questions in the meantime, please leave
                        a message in the "Questions & feedback".
                      </p>
                    </Col>
                  </Row>
                </Alert>
              </Col>
            </Row>
            <Row className="px-2 py-3">
              <Card className="px-0">
                {uploadNewDesignFiles ? (
                  <>
                    <Card.Title className="px-3 pt-3">
                      <Row>
                        <Col>
                          <span>Submit design files</span>
                        </Col>
                        <Col className="text-end">
                          {designFiles && designFiles.length > 0 && (
                            <div className="text-end">
                              <Button
                                onClick={() => setUploadNewDesignFiles(false)}
                                ref={designFilesSubmitBtnRef}
                                size="sm"
                                className={styles.buttonOutline}
                              >
                                Cancel
                              </Button>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Card.Title>
                    <Card.Body className="pt-2 pb-3">
                      <Form.Label
                        htmlFor="designFiles.control"
                        className={styles.detailItemLabel}
                      >
                        Please upload 2D image file or PDF.
                      </Form.Label>
                      <FilePond
                        files={newDesignFiles}
                        instantUpload={false}
                        onupdatefiles={setNewDesignFiles}
                        allowMultiple={true}
                        maxFiles={20}
                        dropOnPage={true}
                        server={null}
                        name="files"
                        labelIdle='Drag & Drop files or <span class="filepond--label-action">Browse</span>'
                        imagePreviewHeight={150}
                        id="designFiles.control"
                        acceptedFileTypes={[
                          'image/jpg',
                          'image/jpeg',
                          'image/png',
                          'application/pdf'
                        ]}
                        required
                        disabled={
                          engagedQuoteStatusHistory.filter(
                            sh =>
                              sh.status ===
                              constants.STATUS_ENGAGED_QUOTE_SAMPLE_NON_DECO_COMPLETE
                          ).length === 0
                            ? true
                            : false
                        }
                      />
                    </Card.Body>
                  </>
                ) : (
                  <>
                    <Card.Title className="px-3 pt-3">
                      <Row>
                        <Col>
                          <span>Submitted design files</span>
                        </Col>
                        <Col className="text-end">
                          <Button
                            onClick={() => {
                              setNewDesignFiles([])
                              setUploadNewDesignFiles(true)
                            }}
                            ref={designFilesUpdateBtnRef}
                            size="sm"
                            className={styles.buttonOutline}
                          >
                            Update
                          </Button>
                        </Col>
                      </Row>
                    </Card.Title>
                    <Card.Body className='pt-2 pb-3'>
                      <Form.Label
                        htmlFor="designFiles.control"
                        className={styles.detailItemLabel}
                      >
                        Design files
                      </Form.Label>
                      <FileGallery files={designFiles} />
                    </Card.Body>
                  </>
                )}
              </Card>
            </Row>
            <Row className="px-2 py-3">
              <Card className="px-0">
                <Card.Title className="px-3 pt-3 pb-2">
                  <Image src={iconMessage} />
                  <span className="ps-3">Questions & feedback</span>
                </Card.Title>
                <Card.Body className="pt-2">
                  <Messages
                    title={'Please submit any comments or questions.'}
                    buttonLabel={'Submit'}
                    quoteId={quoteData?.id}
                    type={constants.TYPE_MSG_QUOTE_DESIGN_REVIEW}
                    numberOfLines={3}
                  //disabled={engagedQuoteStatusHistory.filter(sh => sh.status === constants.STATUS_ENGAGED_QUOTE_DESIGN_SUBMITTED_FOR_REVIEW).length === 0 ? true : false}
                  />
                </Card.Body>
              </Card>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Row className="px-2 pb-3">
        <Col className="px-1 pt-3">
          <div className="text-end">
            <Button
              onClick={onSubmitDesign}
              ref={designFilesSubmitBtnRef}
              size="sm"
              className={styles.button + ' py-2'}
              disabled={
                uploadNewDesignFiles ? false : true
              }
            >
              Submit design files
            </Button>
          </div>
        </Col>
      </Row>
      <Modal show={showCompletionModal} centered>
        <Modal.Header className="py-3">
          <Modal.Title>Did you attach all design files?</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="py-2">
            <Image src={iconCheckGreen} width={50} />
          </div>
          <div className="py-3">
            All design files should be attached before you proceed. If you have
            additional design files, please select "Cancel" and attach them.
          </div>
          <Row className="py-2 px-5">
            <Stack direction="vertical" gap={2}>
              <Button
                variant="outline-secondary"
                className="py-2"
                size="sm"
                onClick={() => {
                  setShowCompletionModal(false)
                }}
              >
                Cancel
              </Button>
              <Button
                className={styles.button + ' py-2'}
                size="sm"
                onClick={onProceedSubmitDesign}
                ref={designFilesSubmitProceedBtnRef}
              >
                Proceed
              </Button>
            </Stack>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal show={showSpinnerModal} fullscreen centered style={{ backgroundColor: 'transparent', opacity: '0.5' }}>
        <Modal.Body className="text-center align-content-center">
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Loading ...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </Container>
  )
}
